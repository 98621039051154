/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useEffect } from 'react';

import Breadcrumbs from '../slices/Breadcrumbs';
import Alert from '../slices/Alert';
import { APIError, APIErrorClass } from '../shared/api_errors';
import useTimeoutVisibility from '../hooks/useTimeoutVisibility';

interface WrapperPageProps {
	title: string;
	success?: { title: string; message: string };
	onVanish?: () => void;
	onAlertClose?: () => void;
	error?: APIError;
	info?: string;
	children: ReactNode;
}

export default function WrapperPage({
	title,
	error,
	info,
	success,
	onAlertClose,
	onVanish,
	children,
}: WrapperPageProps) {
	function handleOnVanish() {
		onVanish && onVanish();
	}

	const { triggerFeedback } = useTimeoutVisibility(handleOnVanish);

	useEffect(() => {
		triggerFeedback();
	}, [error, info, success, triggerFeedback]);

	return (
		<section className="flex flex-col flex-grow w-full">
			<div className="flex flex-col gap-2 flex-grow overflow-y-scroll custom-scrollbar">
				<div className="shadow-md sticky top-0 z-10 bg-white border-t-[1px] border-light-hdr">
					<div className="m-4 flex flex-col gap-4">
						<div className="flex items-center justify-between">
							<div>
								<h1 className="text-xl font-bold">{title}</h1>
							</div>
							<div className="flex flex-wrap justify-center">
								<Breadcrumbs />
							</div>
						</div>
					</div>
				</div>
				<div className="relative">
					<div className="px-2 fixed w-full z-10">
						{!error && success && (
							<Alert
								type="success"
								title={success.title}
								message={success.message}
								onClose={onAlertClose}
							/>
						)}
						{!error && !success && info && (
							<Alert type="info" title="Information" message={info} onClose={onAlertClose} />
						)}
						{error && (
							<Alert
								type={error.error_type() === APIErrorClass.InternalError ? 'danger' : 'warning'}
								title={
									error.error_type() === APIErrorClass.InternalError
										? 'Ops! Something went wrong.'
										: 'Oh, no! Validation errors found.'
								}
								message={
									error.error_type() === APIErrorClass.InternalError
										? 'An internal problem occurred and it was not possible to process your request.'
										: [error.error, ...(error.message ? error.message : [])].join(', ')
								}
								onClose={onAlertClose}
							/>
						)}
					</div>
				</div>
				{children}
			</div>
		</section>
	);
}
