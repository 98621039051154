import React, { useEffect } from 'react';
import FormGroup from '../slices/FormGroup';
import { useAuth } from '../hooks/useAuth';
import Button from '../components/Button';
import { LogOut } from 'lucide-react';

export default function ExitDoorPage() {
	const { login, ready, isAuthenticated } = useAuth();

	useEffect(() => {
		if (!ready) return;
		if (!isAuthenticated) return;
	}, [ready, isAuthenticated]);

	return (
		<div className="flex flex-col items-center gap-8">
			<div className="w-full">
				<FormGroup title="Exit Door" titleAlign="center">
					<div className="flex flex-col gap-4">
						<div className="flex gap-2 items-center">
							<LogOut />
							<h1 className="text-sm">You have logged out!</h1>
						</div>
						<div className="flex items-center justify-center">
							<Button text="Login" variant="link" onClick={login} />
						</div>
					</div>
				</FormGroup>
			</div>
		</div>
	);
}
