import React from 'react';
import Overlay from '../components/Overlay';
import Button, { Variant } from '../components/Button';
import MessageBox from './MessageBox';

export interface AlertBoxProps {
	title: string;
	message: string;
	type?: 'success' | 'warning' | 'danger' | 'info';
	ok?: string;
	onOk?: () => void;
	okDisabled?: boolean;
}

export default function Alert({ title, message, type = 'success', ok = 'OK', onOk, okDisabled }: AlertBoxProps) {
	return (
		<Overlay>
			<MessageBox title={title} type={type}>
				<div className="flex flex-col gap-1 items-center max-w-96">
					{message.split('\n').map((msg) => (
						<p key={msg.replaceAll(' ', '')} className="text-sm text-justify">
							{msg}
						</p>
					))}
				</div>
				<div className="w-32">
					<Button
						text={ok}
						block
						onClick={onOk}
						style="outline"
						variant={type as Variant}
						disabled={okDisabled}
					/>
				</div>
			</MessageBox>
		</Overlay>
	);
}
