import React, { useEffect, useRef } from 'react';

export interface AutoCompleteItem {
	key: string;
	display: string;
}

export interface AutoCompleteProps {
	items: Array<AutoCompleteItem>;
	onSelect: (item: AutoCompleteItem) => void;
	onLeave?: () => void;
}

export default function AutoComplete({ items, onSelect, onLeave }: AutoCompleteProps) {
	const autoCompleteRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (autoCompleteRef.current && !autoCompleteRef.current.contains(event.target as Node)) {
				onLeave && onLeave();
			}
		};

		const handleEscapePress = (event: KeyboardEvent) => {
			if (event.key === 'enter') {
				onLeave && onLeave();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleEscapePress);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleEscapePress);
		};
	}, [onLeave]);

	return (
		<div ref={autoCompleteRef} className={`absolute z-10 ${(!items || !items.length) && 'hidden'}`}>
			<ul className="flex flex-col mt-2 border-2 w-72 bg-white shadow-md">
				{items &&
					items.map((item) => (
						<li
							key={item.key}
							className="px-2 py-1 cursor-pointer hover:bg-slate-100"
							onMouseDown={() => onSelect && onSelect(item)}
						>
							{item.display}
						</li>
					))}
			</ul>
		</div>
	);
}
