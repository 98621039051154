import React, { useEffect } from 'react';
import FormGroup from '../slices/FormGroup';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { LoaderCircle } from 'lucide-react';

export default function LogoutPage() {
	const navigate = useNavigate();
	const { logout, ready, isAuthenticated } = useAuth();

	useEffect(() => {
		if (!ready) return;
		if (!isAuthenticated) return navigate('/auth/exitdoor');

		logout();
	}, [ready, isAuthenticated]);

	return (
		<div className="flex flex-col items-center gap-8">
			<div className="w-full">
				<FormGroup title="Logout" titleAlign="center">
					<div className="flex gap-2 items-center">
						<LoaderCircle className="animate-spin" />
						<h1 className="text-sm">Wait for logout operation to finish!</h1>
					</div>
				</FormGroup>
			</div>
		</div>
	);
}
