import { useEffect, useState } from 'react';

import useAPI from './useAPI';
import { useAuth } from '../useAuth';
import { User } from '../../shared/models/User';
import { useProfile } from '../useProfile';
import { APIError } from '../../shared/api_errors';

export class APIUsersOptions {
	mustFetchUsers: boolean = true;

	constructor(options?: Partial<APIUsersOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

export default function useAPIUsers(options?: Partial<APIUsersOptions>) {
	const { mustFetchUsers } = new APIUsersOptions(options);
	const { user } = useAuth();
	const { tenant } = useProfile();

	const api = useAPI(true, user?.access_token, tenant);
	const [users, setUsers] = useState<User[] | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<APIError | undefined>();

	function clearError() {
		setError(undefined);
	}

	async function fetchUsers() {
		if (!tenant) {
			setUsers(undefined);
			setLoading(false);
			return;
		}

		setUsers(undefined);
		setLoading(true);

		const users = await api.get('/management/users');
		setUsers(users);
		setLoading(false);
	}

	async function revoke(user_id: string) {
		if (!tenant) {
			setLoading(false);
			return;
		}

		try {
			setLoading(true);
			await api.put(`/management/users/${user_id}/revoke`);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function access(user_id: string) {
		if (!tenant) {
			setLoading(false);
			return;
		}

		try {
			setLoading(true);
			await api.put(`/management/users/${user_id}/access/switch`);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function resendInvitation(user_id: string) {
		if (!tenant) {
			setLoading(false);
			return;
		}

		try {
			setLoading(true);
			await api.post(`/management/users/${user_id}/resend-invitation`);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		mustFetchUsers && fetchUsers();
	}, [user, mustFetchUsers]);

	return { users, loading, fetchUsers, revoke, access, resendInvitation, error, clearError };
}
