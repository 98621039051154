import React, { useEffect, useState } from 'react';

import { CRUDFormGroupType } from '../components/CRUDFormGroup';
import { Subscription, SubscriptionStatus, getSubscriptionStatus } from '../shared/models/Subscription';
import { applyMask } from '../components/Input';
import { BeforeSubmitType, useCRUDFormPage } from '../hooks/useCRUDForm';
import GenericCRUDFormPage from './GenericCRUDFormPage';
import { useCreateSubscriptionGroups } from '../hooks/subscription/useCreateSubscriptionGroups';
import { readOnlyGroups } from '../constants/readOnlyGroups';
import { BottomControlCustomAction } from '../slices/BottomControl';
import useAPISubscription from '../hooks/api/useAPISubscription';
import ConfimationBox from '../slices/ConfirmationBox';
import FormGroup from '../slices/FormGroup';
import { timeSince, toLocaleShortDateString } from '../shared/utils';
import { formatPeriod, isOverdue } from '../shared/models/SubscriptionDueDateUtils';
import { HISTORY_ICON, PAUSE_ICON, PLAY_ICON, RESUME_ICON, STOP_ICON } from './util/constants';
import { GRAVITY_VENTRILOQUO_USER } from '../constants/gravity';
import useAPIISPSettings from '../hooks/api/useAPIISPSettings';
import Alert from '../slices/Alert';
import { useTranslation } from 'react-i18next';
import { LoaderCircle, LucideIcon } from 'lucide-react';
import Icon from '@/components/Icon';

const createUrl = '/subscriptions/new';
const endpoint = '/service/subscriptions';

function handleAfterFetch(data: Subscription): object {
	const form = { ...data, price: '' };
	form.service_plan_price = `${data.currency.symbol || ''} ${applyMask((data.service_plan_price as number).toString())}`;

	return form;
}

function handleBeforeSubmit(payload: BeforeSubmitType, form: Subscription) {
	const changedPayload: BeforeSubmitType = {};
	if (!form.id) {
		changedPayload.due_date = parseInt(form.due_day.toString());
		changedPayload.client_id = form.client_id;
		changedPayload.plan_id = form.service_plan_id;
		changedPayload.pool_id = form.infra_nas_pool_id;
	}

	changedPayload.connectivity_user = form.connectivity_user || '';
	changedPayload.connectivity_password = form.connectivity_password || '';
	changedPayload.connectivity_ip = form.connectivity_ip || null;

	return changedPayload;
}

function SubscriptionByIdPage() {
	const { t } = useTranslation('common');
	const [groups, setGroups] = useState<CRUDFormGroupType[]>([]);
	const [confirmTerminate, setConfirmTerminate] = useState(false);
	const [confirmActivate, setConfirmActivate] = useState(false);
	const [confirmToggleHold, setConfirmToggleHold] = useState(false);

	const { settings, error: settingsError } = useAPIISPSettings();
	const { id, form, setForm, handleSave, error, clearError, loading } = useCRUDFormPage(
		endpoint,
		groups,
		createUrl,
		handleAfterFetch,
		handleBeforeSubmit,
	);
	const {
		activate,
		terminate,
		toggleHold,
		loading: subscriptionLoading,
		error: subscriptionError,
		clearError: subscriptionClearError,
	} = useAPISubscription();
	const isCreation = id === 'new';

	const subscription = form as Subscription;
	const { groups: createGroups } = useCreateSubscriptionGroups(subscription, setForm);

	useEffect(() => {
		setGroups(isCreation ? createGroups : readOnlyGroups(t));
	}, [id, createGroups]);

	async function handleActivate() {
		setConfirmActivate(true);
	}

	async function handleActivateOk() {
		if (!id || isCreation) return;
		clearError();
		setConfirmActivate(false);
		const result = await activate(id);
		if (result) setForm(result);
	}

	async function handleToggleHold() {
		setConfirmToggleHold(true);
	}

	async function handleToggleHoldOk() {
		if (!id || isCreation) return;
		clearError();
		setConfirmToggleHold(false);
		const result = await toggleHold(id);
		if (result) setForm(result);
	}

	async function handleTerminate() {
		setConfirmTerminate(true);
	}

	async function handleTerminateOk() {
		if (!id || isCreation) return;
		clearError();
		setConfirmTerminate(false);
		const result = await terminate(id);
		if (result) setForm(result);
	}

	function getCustomAction(subscription: Subscription) {
		const status = getSubscriptionStatus(subscription);
		const loadingIcon: LucideIcon | undefined = subscriptionLoading ? LoaderCircle : undefined;

		const actions: {
			[K in SubscriptionStatus]: BottomControlCustomAction | undefined;
		} = {
			['Pending']: {
				title: 'Activate',
				variant: 'success',
				action: handleActivate,
				icon: loadingIcon ? loadingIcon : PLAY_ICON,
				iconSpin: !!loadingIcon,
				disabled: subscriptionLoading,
			},
			['Active']: {
				title: 'Terminate',
				variant: 'danger',
				action: handleTerminate,
				icon: loadingIcon ? loadingIcon : STOP_ICON,
				iconSpin: !!loadingIcon,
				disabled: subscriptionLoading,
			},
			['Active-&-Suspended']: {
				title: 'Terminate',
				variant: 'danger',
				action: handleTerminate,
				icon: loadingIcon ? loadingIcon : STOP_ICON,
				iconSpin: !!loadingIcon,
				disabled: subscriptionLoading,
			},
			['Terminated']: undefined,
		};

		const holdAction: BottomControlCustomAction | undefined =
			['Active', 'Active-&-Suspended', 'Terminated'].indexOf(status) > -1
				? {
						title: subscription.held_at ? 'Resume' : 'Suspend',
						variant: subscription.held_at ? 'success' : 'warning',
						action: handleToggleHold,
						icon: loadingIcon ? loadingIcon : subscription.held_at ? RESUME_ICON : PAUSE_ICON,
						disabled: subscriptionLoading,
					}
				: undefined;

		const action = subscription.id && actions[status];
		if (!action) return;

		const result = [];
		if (holdAction) {
			result.push(holdAction);
		}
		result.push(action);

		return result;
	}

	function clearErrors() {
		clearError();
		subscriptionClearError();
	}

	return (
		<>
			<GenericCRUDFormPage
				title="Subscription"
				groups={groups}
				backwardUrl="/subscriptions"
				createUrl={createUrl}
				form={form as never}
				setForm={setForm}
				loading={loading}
				handleSave={handleSave}
				error={subscriptionError || settingsError || error}
				onClearError={clearErrors}
				customActions={getCustomAction(subscription)}
			>
				<div className="flex flex-col gap-4">
					{subscription.at_moderation && (
						<Alert
							type="info"
							title="Under Moderation"
							message="This subscription is under moderation. It will remain so until a payment is made and the next due date is no longer overdue."
						/>
					)}
					{!isCreation && (
						<FormGroup title="Payments">
							<div className="max-h-96 overflow-y-scroll">
								<ul className="flex flex-col gap-2">
									{subscription.service_subscription_payment
										?.sort((a, b) => (a.payment_date < b.payment_date ? 1 : -1))
										.map((payment) => (
											<li
												key={payment.id}
												className="pb-2 border-b border-gray-200 last:border-none flex items-center gap-2"
											>
												<div className="flex flex-col">
													<p>
														<span className="text-sm">
															{toLocaleShortDateString(payment.payment_date)}
														</span>
														&nbsp;-&nbsp;
														<span className="text-sm font-bold w-32">
															{applyMask(payment.amount.toString())}
														</span>
													</p>
													<p>
														<span className="text-sm">{formatPeriod(payment.period)}</span>
													</p>
												</div>
											</li>
										))}
								</ul>
							</div>
						</FormGroup>
					)}
					{!isCreation && (
						<FormGroup title="History">
							<div className="max-h-96 overflow-y-scroll">
								<ul className="flex flex-col gap-2">
									{subscription.service_subscription_history
										?.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
										.map((history) => (
											<li
												key={history.id}
												className="pb-2 border-b border-gray-200 last:border-none flex items-center gap-2"
											>
												<Icon
													icon={HISTORY_ICON[history.modification_type].type}
													className={HISTORY_ICON[history.modification_type].color}
												/>
												<div className="flex flex-col">
													<p>
														<span className="text-sm">
															{history.user
																? `${history.user.given_name} ${history.user.family_name}`
																: GRAVITY_VENTRILOQUO_USER}
														</span>
														&nbsp;
														<span className="text-sm font-bold w-32">
															{timeSince(history.created_at)}
														</span>
													</p>
													<p>
														<span className="text-sm">{history.message}</span>
													</p>
												</div>
											</li>
										))}
								</ul>
							</div>
						</FormGroup>
					)}
				</div>
			</GenericCRUDFormPage>
			{confirmTerminate && (
				<ConfimationBox
					title="Action Confirmation"
					message="Are you sure you want to terminate this subscription? You may not be allowed to rollback!"
					onOk={handleTerminateOk}
					onCancel={() => setConfirmTerminate(false)}
					inverted
				/>
			)}
			{confirmActivate && (
				<ConfimationBox
					title="Activate Subscription"
					message="Are you sure you want to activate this subscription?"
					onOk={handleActivateOk}
					onCancel={() => setConfirmActivate(false)}
				/>
			)}
			{confirmToggleHold && settings && (
				<ConfimationBox
					title={subscription.held_at ? 'Resume Subscription' : 'Suspend Subscription'}
					message={
						subscription.held_at
							? subscription.next_due_date &&
								settings.subscription_max_overdue_days &&
								isOverdue(new Date(subscription.next_due_date), settings.subscription_max_overdue_days)
								? 'This subscription will resume but will be sent to moderation. Are you sure?'
								: 'Are you sure you want to resume this subscription?'
							: 'Are you sure you want to suspend this subscription?'
					}
					onOk={handleToggleHoldOk}
					onCancel={() => setConfirmToggleHold(false)}
				/>
			)}
		</>
	);
}

export default SubscriptionByIdPage;
