import React from 'react';

import BottomControl, { BottomControlCustomAction, BottomControlSaveEvent } from '../slices/BottomControl';
import { APIError } from '../shared/api_errors';
import { CRUDFormGroupType } from '../components/CRUDFormGroup';
import { CRUDFormGroups } from '../components/CRUDFormGroups';
import WrapperPage from './WrapperPage';

export interface CRUDFormPageProps {
	title: string;
	groups: Array<CRUDFormGroupType | Array<CRUDFormGroupType>>;
	backwardUrl: string;
	createUrl: string;
	form: never;
	setForm: React.Dispatch<React.SetStateAction<object>>;
	loading: boolean;
	error?: APIError;
	onClearError?: () => void;
	handleSave: (event: BottomControlSaveEvent) => Promise<boolean>;
	customActions?: Array<BottomControlCustomAction>;
	minimalActions?: boolean;
	children?: React.ReactNode;
}

const CRUDFormPage: React.FC<CRUDFormPageProps> = ({
	title,
	groups,
	backwardUrl,
	createUrl,
	form,
	setForm,
	loading,
	error,
	onClearError,
	handleSave,
	customActions,
	minimalActions,
	children,
}) => {
	return (
		<WrapperPage title={title} error={error} onAlertClose={onClearError}>
			<div className="mx-4">
				<CRUDFormGroups form={form} groups={groups} setForm={setForm} loading={loading} />
			</div>
			<div className="mx-4 my-2 flex flex-col flex-grow">{children}</div>
			<BottomControl
				backwardUrl={backwardUrl}
				createUrl={createUrl}
				onSave={handleSave}
				customActions={customActions}
				minimalActions={minimalActions}
				loading={loading}
			/>
		</WrapperPage>
	);
};

export default CRUDFormPage;
