import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enCommon from '../locales/en/common.json';
import enDashboard from '../locales/en/dashboard.json';
import esCommon from '../locales/es/common.json';
import esDashboard from '../locales/es/dashboard.json';
import ptCommon from '../locales/pt/common.json';
import ptDashboard from '../locales/pt/dashboard.json';

i18n.use(initReactI18next).init({
	resources: {
		en: {
			common: enCommon,
			dashboard: enDashboard,
		},
		es: {
			common: esCommon,
			dashboard: esDashboard,
		},
		pt: {
			common: ptCommon,
			dashboard: ptDashboard,
		},
	},
	lng: 'es',
	fallbackLng: 'es',
	ns: ['common', 'dashboard'],
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
