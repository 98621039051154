export interface Speed {
	id: string;
	isp_id: string;
	description?: string;
	up_max_limit: number;
	up_burst: number;
	up_threshold: number;
	up_time_secs: number;
	up_limit_at: number;
	up_priority: number;
	down_max_limit: number;
	down_burst: number;
	down_threshold: number;
	down_time_secs: number;
	down_limit_at: number;
	down_priority: number;
	created_at: Date;
	updated_at: Date;
}

export function formatMikrotikRateLimit(serviceSpeed: Speed): string {
	const formatSpeed = (speed: number): string => {
		if (speed >= 1_000_000) return `${speed / 1_000_000}M`;
		if (speed >= 1_000) return `${speed / 1_000}k`;
		return `${speed}`;
	};

	const upMaxLimit = formatSpeed(serviceSpeed.up_max_limit);
	const downMaxLimit = formatSpeed(serviceSpeed.down_max_limit);
	const upBurst = formatSpeed(serviceSpeed.up_burst);
	const downBurst = formatSpeed(serviceSpeed.down_burst);
	const upThreshold = formatSpeed(serviceSpeed.up_threshold) || upMaxLimit;
	const downThreshold = formatSpeed(serviceSpeed.down_threshold) || downMaxLimit;
	const upTimeSecs = serviceSpeed.up_time_secs || 1;
	const downTimeSecs = serviceSpeed.down_time_secs || 1;
	const upLimitAt = formatSpeed(serviceSpeed.up_limit_at) || upMaxLimit;
	const downLimitAt = formatSpeed(serviceSpeed.down_limit_at) || downMaxLimit;
	const priority = serviceSpeed.up_priority || 7;

	return `${upMaxLimit}/${downMaxLimit} ${upBurst}/${downBurst} ${upThreshold}/${downThreshold} ${upTimeSecs}/${downTimeSecs} ${priority} ${upLimitAt}/${downLimitAt}`;
}
