import React, { useEffect, useState } from 'react';

import useAPIISPSettings from '../hooks/api/useAPIISPSettings';
import GenericCRUDFormPage from './GenericCRUDFormPage';
import { CRUDFormGroupType } from '../components/CRUDFormGroup';
import { ISPSettings, ISPSettingsPayload } from '../shared/models/ISPSettings';

const groups: Array<CRUDFormGroupType> = [
	{
		key: 'custom-checks',
		title: 'Freeradius Rest - Custom Checks',
		rows: [
			{
				key: 'row-2',
				fields: [
					{
						property: 'custom_check_nas_identifier',
						label: 'Check NAS Identifier (ID)',
						highlight: true,
						proportion: 'w-1/4',
						type: 'checkbox',
					},
				],
			},
			{
				key: 'row-3',
				fields: [
					{
						property: 'custom_check_nas_ip_address',
						label: 'Check NAS IP Address',
						highlight: true,
						proportion: 'w-1/4',
						type: 'checkbox',
					},
				],
			},
		],
	},
	{
		key: 'subscriptions',
		title: 'Subscriptions Settings',
		rows: [
			{
				key: 'row-2',
				fields: [
					{
						property: 'subscription_default_password',
						label: 'Suggested PPPoE Password',
						highlight: true,
						proportion: 'w-1/3',
					},
					{
						property: 'subscription_max_overdue_days',
						label: 'Max Overdue Days',
						highlight: true,
						proportion: 'w-1/3',
						type: 'number',
					},
				],
			},
		],
	},
];

export default function ISPSettingsPage() {
	const [form, setForm] = useState<ISPSettings>({} as never);
	const { settings, loading, error, save } = useAPIISPSettings();

	useEffect(() => {
		if (settings) setForm(settings as never);
	}, [settings]);

	async function handleSave() {
		const payload: ISPSettingsPayload = {
			custom_check_nas_identifier:
				typeof form.custom_check_nas_identifier === 'string'
					? form.custom_check_nas_identifier === 'true'
					: form.custom_check_nas_identifier || false,
			custom_check_nas_ip_address:
				typeof form.custom_check_nas_ip_address === 'string'
					? form.custom_check_nas_ip_address === 'true'
					: form.custom_check_nas_identifier || false,
			subscription_default_password: form.subscription_default_password,
			subscription_max_overdue_days:
				typeof form.subscription_max_overdue_days === 'string'
					? parseInt(form.subscription_max_overdue_days)
					: form.subscription_max_overdue_days,
		};
		save(payload);
		return false;
	}

	return (
		<GenericCRUDFormPage
			title="Settings"
			groups={groups}
			backwardUrl="/subscriptions"
			createUrl={'/settings'}
			form={form as never}
			setForm={(form) => setForm(form as never)}
			loading={loading}
			handleSave={handleSave}
			error={error}
			minimalActions
		/>
	);
}
