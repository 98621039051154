import React from 'react';
import { CRUDFormGroupType } from '../components/CRUDFormGroup';
import CRUDFormPage from './CRUDFormPage';

const groups: Array<CRUDFormGroupType> = [
	{
		key: 'basic-data',
		title: 'Data',
		rows: [
			{
				key: 'row-1',
				fields: [
					{ property: 'name', label: 'Name', highlight: true, proportion: 'w-1/2' },
					{ property: 'realm', label: 'Realm', proportion: 'w-1/2' },
				],
			},
			{
				key: 'row-2',
				fields: [{ property: 'address', label: 'Address', proportion: 'w-full' }],
			},
		],
	},
];

function ISPByIdPage() {
	return <CRUDFormPage title="ISPs" groups={groups} endpoint="/isp" backwardUrl="/isp" createUrl="/isp/new" />;
}

export default ISPByIdPage;
