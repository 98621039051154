import React, { useEffect, useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import { useNavigate, useSearchParams } from 'react-router-dom';

import WrapperPage from '../WrapperPage';
import Button from '../../components/Button';
import useAPISAASAccount from '../../hooks/api/useAPISAASAccount';
import DataTable, { DataTableField } from '../../components/DataTable';
import WhiteBox from '../../components/WhiteBox';
import { getCardBrandLogoUrl } from '../../shared/utils';
import Bullet from '../../slices/Bullet';
import ConfimationBox from '../../slices/ConfirmationBox';
import { APIError } from '../../shared/api_errors';
import { Plus } from 'lucide-react';

export default function PaymentMethodsPage() {
	const [searchParams] = useSearchParams();
	const activate = searchParams.get('activate');

	const navigate = useNavigate();
	const {
		paymentMethods,
		loading,
		error: fetchError,
		updatePaymentMethod,
	} = useAPISAASAccount({
		mustFetchPaymentMethods: true,
	});

	const error = fetchError
		? fetchError
		: activate === 'no_default'
			? new APIError('You must set one of your payment methods as default before activating.')
			: undefined;

	const [paymentMethodConfirmation, setPaymentMethodConfirmation] = useState<PaymentMethod | undefined>();

	useEffect(() => {
		if (paymentMethods?.payment_methods.length === 0) navigate('/account/payment-methods/add');
	}, [paymentMethods]);

	const fields: DataTableField<PaymentMethod>[] = [
		{
			title: 'Brand',
			property: 'brand',
			extractor: (row: PaymentMethod) => (
				<img src={getCardBrandLogoUrl(row.card?.brand || '')} alt={row.card?.brand} className="w-12" />
			),
		},
		{
			title: 'Card number',
			property: 'last4',
			extractor: (row: PaymentMethod) => {
				return (
					<div className="flex items-center gap-2">
						{`**** **** **** ${row.card?.last4}`}
						{row.id === paymentMethods?.default && <Bullet type="info" title={'default'} />}
					</div>
				);
			},
		},
		{
			title: 'Expires on',
			property: 'expiry',
			extractor: (row: PaymentMethod) => `${row.card?.exp_month}/${row.card?.exp_year}`,
		},
	];

	async function handleUpdatePaymentMethod() {
		if (!paymentMethodConfirmation) return;
		setPaymentMethodConfirmation(undefined);
		await updatePaymentMethod(paymentMethodConfirmation.id);
	}

	return (
		<WrapperPage title="Your Payment Methods" error={error}>
			<div className="m-4">
				<WhiteBox>
					<div className="flex justify-end">
						<Button
							icon={Plus}
							style="roundedOutline"
							onClick={() => navigate('/account/payment-methods/add')}
						/>
					</div>
					<DataTable
						fields={fields}
						rows={
							paymentMethods && [
								...paymentMethods.payment_methods.filter(
									(method) => method.id === paymentMethods.default,
								),
								...paymentMethods.payment_methods.filter(
									(method) => method.id !== paymentMethods.default,
								),
							]
						}
						actions={[
							{
								title: 'Set as default',
								action: function (row: PaymentMethod): void {
									setPaymentMethodConfirmation(row);
								},
							},
						]}
						onRowAction={(row: PaymentMethod) => row.id !== paymentMethods?.default}
						loading={loading}
					/>
				</WhiteBox>
			</div>
			{paymentMethodConfirmation && (
				<ConfimationBox
					title="Are you sure you want to proceed?"
					message={`You will set the card **** **** **** ${paymentMethodConfirmation.card?.last4} as the default payment method.`}
					onCancel={() => setPaymentMethodConfirmation(undefined)}
					onOk={() => handleUpdatePaymentMethod()}
				/>
			)}
		</WrapperPage>
	);
}
