import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import WrapperPage from '../WrapperPage';
import Button from '../../components/Button';
import useAPISAASAccount from '../../hooks/api/useAPISAASAccount';
import WhiteBox from '../../components/WhiteBox';
import StickyBottom from '../../components/StickyBottom';
import Input from '../../components/Input';
import { LoaderCircle, SendHorizonal } from 'lucide-react';

export default function ActivatePage() {
	const [searchParams] = useSearchParams();
	const setupIntent = searchParams.get('setup_intent');
	const redirectFromAddPaymentMethod = !!setupIntent;

	const navigate = useNavigate();
	const {
		paymentMethods,
		paymentMethodsFetched,
		subscription,
		subscriptionFetched,
		loading,
		loadingAction,
		error,
		subscribe,
		updatePaymentMethodBySetupIntent,
	} = useAPISAASAccount({
		mustFetchSubscription: true,
		mustFetchPaymentMethods: true,
	});

	const [subscriptionConfirmation, setSubscriptionConfirmation] = useState<boolean>(false);

	useEffect(() => {
		if (subscription) {
			window.location.href = `${window.location.origin}/account/subscription`;
			return;
		}

		if (paymentMethods) {
			if (paymentMethods.payment_methods.length === 0) {
				navigate('/account/payment-methods/add?redirect=account/activate');
				return;
			}

			if (!paymentMethods.default) {
				if (redirectFromAddPaymentMethod) {
					updatePaymentMethodBySetupIntent(setupIntent);
					return;
				}

				navigate('/account/payment-methods?activate=no_default');
				return;
			}
		}
	}, [subscription, paymentMethods, loading]);

	function handleConfirmation() {
		if (!subscriptionConfirmation) {
			return;
		}

		subscribe();
	}

	return (
		<WrapperPage title="Account Activation" error={error}>
			<div className="flex flex-col flex-grow justify-between">
				<div className="m-4 flex flex-col flex-grow">
					<WhiteBox className="flex flex-col flex-grow">
						<div className="flex flex-col flex-grow">
							<embed
								className="flex-grow"
								src="https://pdfobject.com/pdf/sample.pdf"
								type="application/pdf"
								width="100%"
								height="600px"
							/>
						</div>
					</WhiteBox>
				</div>
				<StickyBottom>
					<div className="flex items-center gap-2 px-4 h-24">
						{(subscriptionFetched && paymentMethodsFetched && (
							<>
								<Input
									type="checkbox"
									label="Agree to Terms and Conditions"
									disposition="row"
									value={subscriptionConfirmation.toString()}
									onChange={(text) => setSubscriptionConfirmation(text === 'true')}
								/>
								<Button
									icon={loadingAction ? LoaderCircle : SendHorizonal}
									iconSpin={loadingAction}
									style="rounded"
									variant="success"
									text="Submit"
									onClick={handleConfirmation}
									disabled={!subscriptionConfirmation}
								/>
							</>
						)) || <LoaderCircle className="animate-spin" />}
					</div>
				</StickyBottom>
			</div>
		</WrapperPage>
	);
}
