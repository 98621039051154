import React from 'react';

import { DataTableField } from '../components/DataTable';
import { Filter } from '../slices/Filters';

import CRUDPage from './CRUDPage';
import { Client } from '../shared/models/Client';

const fields: Array<DataTableField<Client>> = [
	{ title: 'Name', property: 'name' },
	{ title: 'Address', property: 'address', extractor: (row) => row.address || '' },
];
const endpoint = '/client';
const formUrl = '/customers/new';

export default function ClientPage() {
	const filters: Array<Filter> = [
		{
			id: 'name',
			label: 'Name:',
			operator: {
				field: 'name',
				op: 'contains',
			},
		},
	];

	return (
		<CRUDPage
			title="Clients"
			endpoint={endpoint}
			initialSortField="name"
			fields={fields}
			filters={filters}
			formUrl={formUrl}
			useDefaultActions
		/>
	);
}
