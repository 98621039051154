/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { TailwindHeightClass, TailwindWidthProportionClass } from '../shared/tailwind';
import Select from './Select';
import { Data } from '../shared/crud';

export interface CRUDFormSelectProps {
	field: CRUDFormSelectType;
	form: never;
	setForm: (form: object) => void;
}

export interface CRUDFormSelectType {
	label?: string;
	property: string;
	disabled?: boolean;
	options: Array<{ key: string; label: string }>;
	placeholder?: string;
	highlight?: boolean;
	proportion?: TailwindWidthProportionClass;
	multiline?: TailwindHeightClass;
}

export default function CRUDFormSelect({ field, form, setForm }: CRUDFormSelectProps) {
	return (
		<div key={field.property} className={`flex ${field.proportion || ''} ${field.multiline || ''}`}>
			<Select
				label={field.label}
				options={field.options}
				value={((form as Data)[field.property] as string) || ''}
				placeholder={field.placeholder}
				onChange={(text) => setForm({ ...(form as object), [field.property]: text })}
				highlight={field.highlight}
				disabled={field.disabled}
				full
			/>
		</div>
	);
}
