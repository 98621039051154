import { useEffect, useState } from 'react';
import useAPI from './useAPI';
import { useAuth } from '../useAuth';
import { APIError } from '../../shared/api_errors';
import { useProfile } from '../useProfile';
import { SyncStatus } from '../../shared/models/ISP';

export default function useAPIISP(backInDays: number = 10) {
	const { user } = useAuth();
	const { tenant, saasTenant } = useProfile();

	const api = useAPI(true, user?.access_token, tenant);
	const [syncStatus, setSyncStatus] = useState<SyncStatus[] | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<APIError>();

	async function fetchSyncStatus() {
		if (!tenant || !saasTenant) {
			setSyncStatus(undefined);
			setLoading(false);
			return;
		}

		setSyncStatus(undefined);
		setLoading(true);

		try {
			const syncStatus = await api.get(`/isp/sync/${backInDays}`);
			setSyncStatus(syncStatus);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchSyncStatus();
	}, [user, tenant, saasTenant]);

	return { syncStatus, loading, fetchSyncStatus, error };
}
