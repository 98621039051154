import React from 'react';

const colors: Record<BulletProps['type'], string> = {
	info: 'bg-info text-white border-info-hdr',
	danger: 'bg-danger text-white border-danger-hdr',
	success: 'bg-success text-white border-success-hdr',
	warning: 'bg-warning text-white border-warning-hdr',
};

interface BulletProps {
	type: 'info' | 'danger' | 'success' | 'warning';
	title: string;
	message?: string;
}

export default function Bullet({ type, title, message = '' }: BulletProps) {
	return (
		<div className={`px-2 py-1 rounded-[15px] ${colors[type]} cursor-pointer`}>
			<div className="flex items-center flex-wrap gap-2">
				<div>
					<h6 className={`font-bold text-sm ${message ? 'mb-2' : ''}`}>{title}</h6>
					{message && <span className="font-normal text-sm">{message}</span>}
				</div>
			</div>
		</div>
	);
}
