import React, { Outlet, useNavigate } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { useEffect } from 'react';

function AuthorizedRoute() {
	const navigate = useNavigate();
	const { isAuthenticated, ready } = useAuth();

	useEffect(() => {
		if (!ready) return;
		if (!isAuthenticated) {
			navigate('/auth/login');
		}
	}, [ready, isAuthenticated]);

	return <Outlet />;
}

export default AuthorizedRoute;
