import React from 'react';
import useAuthService from '../hooks/useAuthService';

export default function TokenPage() {
	const { user } = useAuthService();

	return (
		<section className="flex-grow flex flex-col p-4">
			{user?.access_token && <textarea className="h-48" defaultValue={user?.access_token} />}
		</section>
	);
}
