import React, { createContext, ReactNode } from 'react';
import useAuthService from '../hooks/useAuthService';
import { User } from 'oidc-client-ts';

export interface AuthContextProps {
	isAuthenticated: boolean;
	ready: boolean;
	user: User | null;
	login: () => Promise<void>;
	logout: () => Promise<void>;
	handleCallback: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const { user, login, logout, ready, handleCallback } = useAuthService();

	return (
		<AuthContext.Provider value={{ user, isAuthenticated: !!user, login, logout, ready, handleCallback }}>
			{children}
		</AuthContext.Provider>
	);
};
