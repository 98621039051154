import React from 'react';

import Input from '../components/Input';
import Select from '../components/Select';
import { PrismaCondition } from '../hooks/api/query';
import { Search, X } from 'lucide-react';

export interface FieldOperator {
	field: string;
	op: keyof PrismaCondition;
	value?: never | null;
}

export interface Or {
	operators: Array<FieldOperator>;
}

export type FieldOperatorResolver = (value?: string) => Array<FieldOperator> | Or | undefined;

export function isOr(field: FieldOperator[] | Or): field is Or {
	return (field as Or).operators !== undefined;
}

export interface Filter {
	id: string;
	label?: string;
	placeholder?: string;
	initialValue?: string;
	options?: Array<{ key: string; label: string }>;
	operator: FieldOperator | FieldOperatorResolver;
}

export interface FilterValues {
	[key: string]: string | undefined;
}

export interface FiltersProp {
	filters: Array<Filter>;
	filterValues: FilterValues;
	onFilterChange: (filter: Filter, value?: string) => void;
}

export default function Filters({ filters, filterValues, onFilterChange }: FiltersProp) {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-row gap-2 flex-wrap">
				{filters.map((filter) => (
					<div key={filter.id} className="w-64">
						{(filter.options && (
							<Select
								condensed
								rounded
								disposition="row"
								label={filter.label}
								placeholder={filter.placeholder}
								options={filter.options}
								value={filterValues[filter.id] || ''}
								onChange={(value) => onFilterChange(filter, value)}
							/>
						)) || (
							<Input
								label={filter.label}
								placeholder={filter.placeholder}
								value={filterValues[filter.id] || ''}
								onChange={(value) => onFilterChange(filter, value)}
								disposition="row"
								condensed
								rounded
								contentIcon={{
									type: X,
									color: 'text-red-600',
									action: () => onFilterChange(filter),
								}}
								noContentIcon={{
									type: Search,
									color: 'text-gray-400',
								}}
							/>
						)}
					</div>
				))}
			</div>
		</div>
	);
}
