/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { DataTableAction, DataTableField } from '../components/DataTable';
import { Filter, Or } from '../slices/Filters';

import { User } from '../shared/models/User';
import Input from '../components/Input';
import { useProfile } from '../hooks/useProfile';
import ConfimationBox from '../slices/ConfirmationBox';
import GenericCRUDPage from './GenericCRUDPage';
import useCRUDPage from '../hooks/useCRUDPage';
import { Audit } from '../shared/models/Audit';
import Button from '../components/Button';
import Bullet from '../slices/Bullet';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const endpoint = '/audit';

function generateCustomLink(saasAdmin: boolean, entity: string, id: string): string | undefined {
	const links: { [key: string]: string | undefined } = {
		Client: (!saasAdmin && `/customers/${id}`) || undefined,
		InfraNAS: (!saasAdmin && `/infra/nas/${id}`) || undefined,
		InfraNASPool: (!saasAdmin && `/infra/nas/${id}`) || undefined,
		ISP: (saasAdmin && `/isps/${id}`) || undefined,
		ServiceSpeed: (!saasAdmin && `/service/speeds/${id}`) || undefined,
		ServicePlan: (!saasAdmin && `/service/plans/${id}`) || undefined,
		ServicePlanType: (!saasAdmin && `/service/plans/${id}`) || undefined,
		ServiceSubscription: (!saasAdmin && `/subscriptions/${id}`) || undefined,
		ServiceType: (!saasAdmin && `/service/types/${id}`) || undefined,
		User: (saasAdmin && `/users/${id}`) || undefined,
	};

	return links[entity] || undefined;
}

function resolveLink(navigate: NavigateFunction, saasAdmin: boolean, row: Audit): React.ReactNode {
	const url = row.action !== 'REMOVE' ? generateCustomLink(saasAdmin, row.resource_type, row.resource_id) : undefined;

	return (
		(url && <Button text={row.resource_id} variant="link" onClick={() => url && navigate(url)} />) ||
		row.resource_id
	);
}

function resolveActionColor(action: string): 'info' | 'danger' | 'success' | 'warning' {
	if (action === 'CREATE') return 'success';
	if (action === 'UPDATE') return 'info';
	if (action === 'DELETE') return 'danger';
	return 'warning';
}

export default function AuditPage() {
	const navigate = useNavigate();
	const { currentIsSaas: saasAdmin } = useProfile();

	const filters: Array<Filter> = [];

	const {
		data,
		error,
		loading,
		page,
		pageSize,
		totalRecords,
		sortField,
		sortOrder,
		filterValues,
		handleOnPageSize,
		handleOnPage,
		handleOnRefresh,
		handleOnSort,
		handleFilterChange,
		handleRemove,
	} = useCRUDPage<Audit>({
		endpoint,
		initialSortField: 'created_at',
		initialSortOrder: 'desc',
	});

	let fields: Array<DataTableField<Audit>> = [
		{
			title: 'Date',
			property: 'created_at',
			extractor: (row) =>
				new Date(row.created_at).toLocaleDateString(navigator.language, {
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
				}),
		},
		{
			title: 'ISP',
			property: 'isp',
			extractor: (row) => row.isp?.name,
		},
		{
			title: 'User',
			property: 'user',
			extractor: (row) => row.user.email,
		},
		{
			title: 'Resource',
			property: 'resource',
			extractor: (row) => (
				<p className="flex gap-2 items-center">
					<Bullet type={resolveActionColor(row.action)} title={row.action} />
					<span className="font-bold">{row.resource_type}</span>
					{resolveLink(navigate, saasAdmin, row)}
				</p>
			),
		},
		{
			title: 'Details',
			property: 'description',
		},
	];

	if (!saasAdmin) {
		fields = fields.filter((field) => field.property !== 'isp');
	}

	return (
		<GenericCRUDPage
			title="Audit Logs"
			filters={filters}
			filterValues={filterValues}
			data={data}
			fields={fields}
			error={error}
			loading={loading}
			page={page}
			pageSize={pageSize}
			totalRecords={totalRecords}
			sortField={sortField}
			sortOrder={sortOrder}
			useDefaultActions={false}
			onPageSize={handleOnPageSize}
			onPage={handleOnPage}
			onRefresh={handleOnRefresh}
			onSort={handleOnSort}
			onFilterChange={handleFilterChange}
			onRemove={handleRemove}
		/>
	);
}
