import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';

import WrapperPage from '../WrapperPage';
import WhiteBox from '../../components/WhiteBox';
import useAPISAASAccount from '../../hooks/api/useAPISAASAccount';
import { APIError } from '../../shared/api_errors';
import Button from '../../components/Button';
import config from '../../shared/config';
import StickyBottom from '../../components/StickyBottom';
import { LoaderCircle } from 'lucide-react';

const stripePromise = loadStripe(config.stripe.public_key);

export default function AddPaymentMethodPage() {
	const [searchParams] = useSearchParams();
	const redirect = searchParams.get('redirect');

	const [setupIntentFormLoaded, setSetupIntentFormLoaded] = useState(false);
	const {
		setupIntent,
		error: apiSetupIntentError,
		loading,
		loadingAction,
	} = useAPISAASAccount({ mustFetchSetupIntent: true });
	const [setupError, setSetupError] = useState<APIError | undefined>();
	const formRef = useRef<HTMLFormElement>(null);

	const error = apiSetupIntentError || setupError;

	function handleSetupFormError(error?: string) {
		if (error) setSetupError(new APIError(error));
		else setSetupError(undefined);
	}

	function handleSaveClick() {
		if (!formRef.current) return;
		formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
	}

	return (
		<WrapperPage title="Add Payment Method" error={error}>
			<div className="flex flex-col flex-grow justify-between">
				<div className="m-4">
					<WhiteBox>
						{setupIntent && !loading ? (
							<Elements stripe={stripePromise} options={{ clientSecret: setupIntent.client_secret }}>
								<SetupIntentForm
									formRef={formRef}
									onError={handleSetupFormError}
									redirect={redirect}
									onLoaded={() => setSetupIntentFormLoaded(true)}
								/>
							</Elements>
						) : (
							<LoaderCircle className="animate-spin" />
						)}
					</WhiteBox>
				</div>
			</div>
			<StickyBottom>
				<div className="flex items-center gap-2 px-4 h-24">
					<Button
						text="Save Payment Method"
						type="submit"
						onClick={handleSaveClick}
						disabled={!setupIntentFormLoaded || loading || loadingAction}
					/>
				</div>
			</StickyBottom>
		</WrapperPage>
	);
}

function SetupIntentForm({
	formRef,
	onLoaded,
	onError,
	redirect,
}: {
	formRef: RefObject<HTMLFormElement>;
	onLoaded: () => void;
	onError: (error?: string) => void;
	redirect: string | null;
}) {
	const stripe = useStripe();
	const elements = useElements();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!loaded && stripe && elements) setLoaded(true);
	}, [stripe, elements]);

	useEffect(() => {
		if (loaded) onLoaded();
	}, [loaded]);

	const handleSubmit = async (event: React.FormEvent) => {
		onError(undefined);
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		const result = await stripe.confirmSetup({
			elements,
			confirmParams: {
				return_url: !redirect
					? `${window.location.origin}/account/payment-methods`
					: `${window.location.origin}/${redirect}`,
			},
		});

		if (result.error && result.error.message) {
			onError(result.error.message);
		}
	};

	return (
		<form ref={formRef} onSubmit={handleSubmit} className="flex flex-col gap-4">
			<PaymentElement />
		</form>
	);
}
