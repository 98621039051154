import React, { Key, KeyboardEvent } from 'react';

export interface SelectionItem<T> {
	key: T;
	label: string;
}

export interface SelectionProps<T> {
	tabs: Array<SelectionItem<T>>;
	active?: string;
	onSelect?: (selectedTab: T) => void;
}

function Selection<T extends Key>({ tabs, active, onSelect }: SelectionProps<T>) {
	const handleTabSelect = (tab: T) => {
		onSelect && onSelect(tab);
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>, tab: T) => {
		if (event.key === 'Enter' || event.key === ' ') {
			onSelect && onSelect(tab);
		}
	};

	return (
		<ul
			className="border border-gray-300 flex items-center sm:h-10 px-2 rounded-lg whitespace-nowrap max-sm:flex-wrap max-sm:justify-center"
			role="tablist"
		>
			{tabs.map((tab, index) => (
				<li key={tab.key} className="relative group" role="tab" aria-selected={active === tab.key}>
					<button
						onClick={() => handleTabSelect(tab.key)}
						onKeyDown={(e) => handleKeyDown(e, tab.key)}
						tabIndex={0}
						className={`sm:px-4 px-2 sm:py-2 py-1 text-sm font-normal text-gray-600 capitalize block ${
							active === tab.key ? 'text-primary' : ''
						}`}
					>
						{tab.label}
					</button>
					{/* Add divider unless it's the last tab */}
					{index !== tabs.length - 1 && (
						<span className="absolute w-px h-5 bg-gray-300 top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2"></span>
					)}
				</li>
			))}
		</ul>
	);
}

export default Selection;
