import React from 'react';

import { useProfile } from '../hooks/useProfile';
import WrapperPage from './WrapperPage';
import FormGroup from '../slices/FormGroup';
import useAPIISP from '../hooks/api/useAPIISP';
import SyncStatusLineChart from '../slices/SyncStatusLineChart';

export default function HomePage() {
	const { sessionUser, tenant, saasTenant } = useProfile();
	const { syncStatus } = useAPIISP(10);

	return (
		<WrapperPage title="Dashboard">
			<div className="flex flex-col gap-4">
				<div className="mx-4 flex">
					<FormGroup
						title={sessionUser ? `Welcome, ${`${sessionUser.given_name} ${sessionUser.family_name}`}` : ''}
					>
						{tenant && (
							<div className="text-sm">
								<p>
									<span className="font-bold">Current Tenant:</span> {tenant.name}
								</p>
								<p>
									<span className="font-bold">Current Role:</span>{' '}
									<span className="text-primary">{tenant.role}</span>
								</p>
							</div>
						)}
					</FormGroup>
				</div>
				{saasTenant && syncStatus && (
					<div className="mx-4 flex">
						<FormGroup title="NAS Synchronization" grow>
							<SyncStatusLineChart data={syncStatus} />
						</FormGroup>
					</div>
				)}
			</div>
		</WrapperPage>
	);
}
