import React from 'react';

import { DataTableField } from '../components/DataTable';
import { Filter } from '../slices/Filters';

import CRUDPage from './CRUDPage';
import {
	Subscription,
	SubscriptionStatus,
	getSubscriptionStatus,
	getSubscriptionStatusDescription,
	subscriptionStatuses,
} from '../shared/models/Subscription';
import { applyMask } from '../components/Input';
import { formatMikrotikRateLimit } from '../shared/models/service/Speed';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const fields = (t: TFunction): Array<DataTableField<Subscription>> => [
	{
		title: t('sc.fields.status'),
		property: 'started_at',
		extractor: (row) => `${getSubscriptionStatusDescription(t, getSubscriptionStatus(row))}`,
	},
	{ title: t('sc.fields.customer'), property: 'client_id', extractor: (row: Subscription) => row.client.name },
	{
		title: t('sc.fields.connectivity'),
		property: 'connectivity_user',
		extractor: (row: Subscription) => `${row.connectivity_user} \\ ${row.connectivity_password}`,
	},
	{ title: t('sc.fields.plan'), property: 'service_plan_name' },
	{
		title: t('sc.fields.price'),
		property: 'service_plan_price',
		extractor: (row: Subscription) => applyMask(row.service_plan_price.toString()),
	},
	{
		title: t('sc.fields.recurrency'),
		property: 'service_plan_recurrency_interval',
		extractor: (row) => t(`models.subscriptions.recurrency.${row.service_plan_recurrency_interval}`),
	},
	{
		title: t('sc.fields.speed'),
		property: 'connectivity_speed_id',
		extractor: (row) => (!row.held_at ? formatMikrotikRateLimit(row.service_speed) : 'Connection Suspended'),
	},
	{
		title: t('sc.fields.nas'),
		property: 'infra_nas_pool_id',
		extractor: (row) => {
			const pool = row.infra_nas_pool;
			const nas = row.infra_nas_pool.infra_nas;
			if (!nas) return 'No pool found';
			let cell = `${nas.name} (${nas.ip}) | Pool: ${pool.name}`;
			if (row.connectivity_ip) cell = `${cell} | Static IP: ${row.connectivity_ip}`;
			return cell;
		},
	},
];
const endpoint = '/service/subscriptions';
const formUrl = '/subscriptions/new';

export default function SubscriptionPage() {
	const { t } = useTranslation();
	const filters: Array<Filter> = [
		{
			id: 'status',
			label: `${t('sc.filters.status')}:`,
			options: Object.values(subscriptionStatuses).map((value) => ({
				key: value,
				label: getSubscriptionStatusDescription(t, value),
			})),
			initialValue: 'Active' as SubscriptionStatus,
			operator: (value?: string) => {
				const status: SubscriptionStatus = value as SubscriptionStatus;
				if (status === 'Active')
					return [
						{ field: 'started_at', op: 'not', value: null },
						{ field: 'canceled_at', op: 'equals', value: null },
					];
				if (status === 'Active-&-Suspended')
					return [
						{ field: 'started_at', op: 'not', value: null },
						{ field: 'canceled_at', op: 'equals', value: null },
						{ field: 'held_at', op: 'not', value: null },
					];
				if (status === 'Pending') return [{ field: 'started_at', op: 'equals', value: null }];
				if (status === 'Terminated')
					return [
						{ field: 'started_at', op: 'not', value: null },
						{ field: 'canceled_at', op: 'not', value: null },
					];
			},
		},
		{
			id: 'customer',
			label: `${t('sc.filters.customer')}:`,
			operator: {
				field: 'client.name',
				op: 'contains',
			},
		},
		{
			id: 'connectivity-user',
			label: `${t('sc.filters.username')}:`,
			operator: {
				field: 'connectivity_user',
				op: 'contains',
			},
		},
		{
			id: 'nas-name',
			label: `${t('sc.filters.nas-name')}:`,
			operator: {
				field: 'infra_nas_pool.infra_nas.name',
				op: 'contains',
			},
		},
		{
			id: 'nas-ip',
			label: `${t('sc.filters.nas-ip')}:`,
			operator: {
				field: 'infra_nas_pool.infra_nas.ip',
				op: 'contains',
			},
		},
	];

	return (
		<CRUDPage
			title={t('sc.title')}
			endpoint={endpoint}
			initialSortField="created_at"
			fields={fields(t)}
			filters={filters}
			formUrl={formUrl}
			useDefaultActions
		/>
	);
}
