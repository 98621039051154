import React from 'react';

import { Speed } from '../../shared/models/service/Speed';
import { CRUDFormGroupType } from '../../components/CRUDFormGroup';
import { CRUDFormFieldAutoComplete } from '../../components/CRUDFormField';
import { Type } from '../../shared/models/service/Type';
import CRUDFormPage from '../CRUDFormPage';

const groups: Array<CRUDFormGroupType> = [
	{
		key: 'basic-data',
		title: 'Basic Data',
		rows: [
			{
				key: 'row-1',
				fields: [
					{ property: 'name', label: 'Name', highlight: true, proportion: 'w-1/2' },
					{
						property: 'service_speed_id',
						label: 'Service Speed',
						proportion: 'w-1/2',
						autoComplete: {
							relationship: 'service_speed',
							endpoint: '/service/speed',
							searchField: 'description',
							quick: true,
							parseKey: (row: Speed): string => row.id,
							parseDisplay: (row: Speed): string => row.description || '',
						},
					} as CRUDFormFieldAutoComplete<Type, Speed>,
				],
			},
			{
				key: 'row-2',
				fields: [{ property: 'description', label: 'Description', proportion: 'w-full', multiline: 'h-28' }],
			},
		],
	},
];

function TypeByIdPage() {
	return (
		<CRUDFormPage
			title="Types"
			groups={groups}
			endpoint="/service/type"
			backwardUrl="/service/types"
			createUrl="/service/types/new"
		/>
	);
}

export default TypeByIdPage;
