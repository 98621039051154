import React, { useRef, useEffect } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	LineElement,
	PointElement,
	Title,
	Tooltip,
	Legend,
	LineController,
	BarController,
} from 'chart.js';
import { blue, red, green, yellow } from 'tailwindcss/colors';
import { toLocaleShortDateString } from '@/shared/utils';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	LineElement,
	PointElement,
	Title,
	Tooltip,
	Legend,
	LineController,
	BarController,
);

export interface SyncStatus {
	date: string;
	syncedDevices: number;
	totalDevices: number;
	success: number;
	errors: number;
}

export default function SyncStatusChart({ data }: { data: SyncStatus[] }) {
	const chartRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		const chartInstance = new ChartJS(chartRef.current!, {
			type: 'line',
			data: {
				labels: data.map((item) => toLocaleShortDateString(item.date, { timeZone: 'UTC' })),
				datasets: [
					{
						label: 'Total NAS',
						data: data.map((item) => item.totalDevices),
						fill: false,
						borderColor: blue[600],
						backgroundColor: blue[600],
						tension: 0.1,
					},
					{
						label: 'NAS Synced',
						data: data.map((item) => item.syncedDevices),
						fill: false,
						borderColor: yellow[400],
						backgroundColor: yellow[400],
						tension: 0.1,
					},
					{
						label: 'Sync Success',
						data: data.map((item) => item.success),
						backgroundColor: green[600],
						borderColor: green[600],
						barThickness: 4,
						type: 'bar',
						stack: 'syncs',
						order: 1,
					},
					{
						label: 'Sync Error',
						data: data.map((item) => item.errors),
						backgroundColor: red[600],
						borderColor: red[600],
						barThickness: 4,
						type: 'bar',
						stack: 'syncs',
						order: 2,
					},
				],
			},
			options: {
				scales: {
					x: {
						beginAtZero: true,
					},
					y: {
						beginAtZero: true,
					},
				},
				plugins: {
					legend: {
						display: true,
						position: 'top',
					},
				},
				responsive: true,
				maintainAspectRatio: false,
			},
		});

		return () => {
			// Cleanup the chart instance when the component unmounts
			chartInstance.destroy();
		};
	}, [data]);

	return <canvas ref={chartRef} className="h-96 w-full" />;
}
