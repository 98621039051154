export function isServerError(statusCode: number) {
	return statusCode >= 500 && statusCode <= 599;
}

export function isBadRequest(statusCode: number) {
	return statusCode >= 400 && statusCode <= 499;
}

export enum APIErrorClass {
	InternalError,
	BadRequest,
	Unknown,
}

export class APIError {
	constructor(
		public error: string,
		public statusCode?: number,
		public message?: string[],
	) {}

	error_type = (): APIErrorClass => {
		if (this.statusCode) {
			if (isServerError(this.statusCode)) return APIErrorClass.InternalError;
			if (isBadRequest(this.statusCode)) return APIErrorClass.BadRequest;
		}

		return APIErrorClass.Unknown;
	};
}
