import React from 'react';
import Button, { Size, Style, Variant } from '../components/Button';
import { Home } from 'lucide-react';

const styleValues: Style[] = Object.values(Style);
const variantValues: Variant[] = Object.values(Variant);
const sizeValues: Size[] = Object.values(Size);

export default function Buttons() {
	return (
		<div className="m-4 flex flex-col gap-4">
			<h1 className="text-xl font-bold">Button component variations</h1>
			{styleValues.map((style) => (
				<>
					<h2 className="text-md font-bold capitalize">{style}</h2>
					<div key={style} className="inline-flex flex-wrap items-center gap-2">
						{variantValues.map((variant) => (
							<Button key={style + variant} variant={variant} style={style} text={variant} />
						))}
					</div>
				</>
			))}
			<h2 className="text-md font-bold capitalize">Icon</h2>
			<div className="inline-flex flex-wrap gap-2">
				{styleValues.map((style) => (
					<Button key={style} variant="primary" text="Default" style={style} icon={Home} />
				))}
			</div>
			<h2 className="text-md font-bold capitalize">Sizes</h2>
			<div className="inline-flex flex-wrap gap-2">
				{sizeValues.map((size) => (
					<Button key={size} variant="primary" text={`Size ${size}`} size={size} />
				))}
			</div>
		</div>
	);
}
