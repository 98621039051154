import React, { useEffect } from 'react';
import FormGroup from '../slices/FormGroup';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { LoaderCircle } from 'lucide-react';

export default function CallbackPage() {
	const navigate = useNavigate();
	const { handleCallback, ready, isAuthenticated } = useAuth();

	useEffect(() => {
		if (!ready) return;
		if (isAuthenticated) return navigate('/');
		handleCallback().then(() => {
			navigate('/');
		});
	}, [ready, isAuthenticated]);

	return (
		<div className="flex flex-col items-center gap-8">
			<div className="w-full">
				<FormGroup title="Login Confirmation" titleAlign="center">
					<div className="flex gap-2 items-center">
						<LoaderCircle className="animate-spin" />
						<h1 className="text-sm">
							Wait for login operation to <b>complete</b>!
						</h1>
					</div>
				</FormGroup>
			</div>
		</div>
	);
}
