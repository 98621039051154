import { useEffect, useState } from 'react';
import useAPI from './useAPI';
import { APIError } from '../../shared/api_errors';

export default function useAPIVersion() {
	const api = useAPI();
	const [version, setVersion] = useState<string>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<APIError>();

	async function fetchVersion() {
		setVersion(undefined);
		setLoading(true);

		try {
			const { version } = await api.get('/version');
			setVersion(version);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchVersion();
	}, []);

	return { version, loading, fetchVersion, error };
}
