import React, { HTMLInputTypeAttribute } from 'react';
import Input, { InputActionIcon, InputSpinningIcon } from './Input';
import InputAutoComplete from './InputAutoComplete';
import { TailwindHeightClass, TailwindWidthProportionClass } from '../shared/tailwind';
import { Data, TData } from '../shared/crud';

function isCRUDFormFieldWithAutoComplete<TForm, TRelationship>(
	field: CRUDFormFieldType,
): field is CRUDFormFieldAutoComplete<TForm, TRelationship> {
	return (field as CRUDFormFieldAutoComplete<TForm, TRelationship>).autoComplete !== undefined;
}

export interface CRUDFormFieldProps {
	field: CRUDFormFieldType;
	form: never;
	setForm: (form: object) => void;
}

export interface CRUDFormFieldBase {
	type?: HTMLInputTypeAttribute;
	property: string;
	disabled?: boolean;
	valueExtractor?: (form: never) => string;
	onChange?: (value: string) => void;
	label?: string;
	placeholder?: string;
	highlight?: boolean;
	proportion?: TailwindWidthProportionClass;
	multiline?: TailwindHeightClass;
	icon?: InputSpinningIcon;
	actionIcon?: InputActionIcon;
	mask?: 'decimal';
}

export interface CRUDFormFieldAutoComplete<TForm, TRelationship> extends CRUDFormFieldBase {
	autoComplete: {
		relationship:
			| string
			| {
					resolveValue: (form: TForm) => TRelationship | undefined;
					select: (selected: TRelationship, key: string) => void;
			  };
		endpoint: string;
		size?: number;
		quick?: boolean;
		searchField: string;
		parseKey: (row: TRelationship) => string;
		parseDisplay: (row: TRelationship) => string;
	};
}

export type CRUDFormFieldType<TForm = object, TRelationship = object> =
	| CRUDFormFieldBase
	| CRUDFormFieldAutoComplete<TForm, TRelationship>;

function CRUDFormField({ field, form, setForm }: CRUDFormFieldProps) {
	function value() {
		return field.valueExtractor ? field.valueExtractor(form) : ((form as Data)[field.property] as string) || '';
	}

	const handleChange = (value: string) => {
		if (field.onChange) {
			field.onChange(value);
		} else {
			setForm((prevForm: object) => ({
				...prevForm,
				[field.property]: value,
			}));
		}
	};

	function autoCompleteValue(field: CRUDFormFieldAutoComplete<unknown, unknown>) {
		if (typeof field.autoComplete.relationship !== 'string')
			return field.autoComplete.relationship.resolveValue(form);
		else return (form as TData<object>)[field.autoComplete.relationship];
	}

	function handleAutoCompleteOnSelect(field: CRUDFormFieldAutoComplete<unknown, unknown>) {
		return (selected: unknown, key: string): void => {
			if (typeof field.autoComplete.relationship === 'string')
				setForm({
					...(form as object),
					[field.autoComplete.relationship]: selected,
					[field.property]: key,
				});
			else field.autoComplete.relationship.select(selected, key);
		};
	}

	function handleAutoCompleteOnClear(field: CRUDFormFieldAutoComplete<unknown, unknown>) {
		return (): void => {
			if (typeof field.autoComplete.relationship === 'string')
				setForm({
					...(form as object),
					[field.autoComplete.relationship]: null,
					[field.property]: null,
				});
			else field.autoComplete.relationship.select(null, '');
		};
	}

	return (
		<div
			key={field.property}
			className={`flex ${isCRUDFormFieldWithAutoComplete(field) ? 'flex-col' : ''} ${field.proportion || ''} ${field.multiline || ''}`}
		>
			{!isCRUDFormFieldWithAutoComplete(field) && (
				<Input
					type={field.type}
					mask={field.mask}
					key={field.property}
					label={field.label}
					placeholder={field.placeholder}
					highlight={field.highlight}
					value={value()}
					onChange={handleChange}
					multiline={!!field.multiline}
					icon={field.icon}
					contentIcon={field.actionIcon}
					disabled={field.disabled}
					disposition={field.type === 'checkbox' ? 'row' : 'column'}
					full
				/>
			)}
			{isCRUDFormFieldWithAutoComplete(field) && (
				<InputAutoComplete
					field={field}
					value={autoCompleteValue(field)}
					endpoint={field.autoComplete.endpoint}
					size={field.autoComplete.size}
					quick={field.autoComplete.quick}
					searchField={field.autoComplete.searchField}
					parseKey={field.autoComplete.parseKey}
					parseDisplay={field.autoComplete.parseDisplay}
					actionIcon={field.actionIcon}
					onSelect={handleAutoCompleteOnSelect(field)}
					onClear={handleAutoCompleteOnClear(field)}
				/>
			)}
		</div>
	);
}

export default CRUDFormField;
