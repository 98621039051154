import React, { ReactNode } from 'react';

export default function Centered({ children }: { children: ReactNode }) {
	return (
		<div className="relative bg-gray-100 max-lg:ml-0 translate-x-0 duration-300 flex flex-col flex-1 items-stretch h-screen overflow-scroll">
			<div className="flex flex-col flex-grow justify-center">
				<div className="w-[516px] px-[30px] mx-auto my-[150px]">{children}</div>
			</div>
		</div>
	);
}
