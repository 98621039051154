import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './shared/i18n';
import reportWebVitals from './reportWebVitals';

import { RouterProvider } from 'react-router-dom';

import router from './shared/routes';
import { ProfileProvider } from './context/ProfileContext';
import { AuthProvider } from './context/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<AuthProvider>
			<ProfileProvider>
				<RouterProvider router={router} />
			</ProfileProvider>
		</AuthProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
