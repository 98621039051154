import React from 'react';
import WrapperPage from './WrapperPage';

export default function HelpPage() {
	return (
		<WrapperPage title="Help">
			<iframe
				className="airtable-embed bg-transparent flex flex-grow"
				src="https://airtable.com/embed/app5a8qDhF9MBKkrq/pag6OguVMM9UyMZGP/form "
				width="100%"
				height="533"
			></iframe>
		</WrapperPage>
	);
}
