import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, ChevronDown, ChevronUp, Globe } from 'lucide-react';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface LanguageSwitcherProps {
	initialLanguage?: string;
	fold: boolean;
}

type Language = {
	code: string;
	name: string;
	flag: string;
};

const languages: Language[] = [
	{ code: 'en', name: 'English', flag: '🇬🇧' },
	{ code: 'es', name: 'Español', flag: '🇪🇸' },
	{ code: 'pt', name: 'Português', flag: '🇧🇷' },
];

function LanguageSwitcher({ initialLanguage = 'es', fold }: LanguageSwitcherProps) {
	const { i18n } = useTranslation();
	const [active, setActive] = useState(false);
	const [currentLanguage, setCurrentLanguage] = useState<Language>(
		languages.find((lang) => lang.code === initialLanguage) || languages[0],
	);

	const handleLanguageChange = (selectedLanguage: Language) => {
		setCurrentLanguage(selectedLanguage);
		i18n.changeLanguage(selectedLanguage.code);
		setActive(false);
	};

	const handleDropdownToggle = (isOpen: boolean) => {
		setActive(isOpen);
	};

	return (
		<DropdownMenu onOpenChange={handleDropdownToggle}>
			<DropdownMenuTrigger asChild>
				<button
					className={`text-sm rounded-lg h-12 w-full overflow-hidden cursor-pointer flex gap-2 items-center justify-center outline-none whitespace-nowrap text-gray-400 hover:bg-primary-50 hover:text-primary hover:outline-none [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 ${fold ? 'p-2' : 'p-4'} ${
						fold ? 'md:w-12' : 'md:w-fit'
					}`}
				>
					<Globe className="h-2 w-2" />
					{!fold && (
						<>
							{currentLanguage.flag} {currentLanguage.name}
						</>
					)}
					{fold ? null : active ? (
						<ChevronUp className="ml-auto h-4 w-4" />
					) : (
						<ChevronDown className="ml-auto h-4 w-4" />
					)}
				</button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className={`w-[140px] bg-white ${fold ? 'ml-2 md:w-[50px]' : ''}`}>
				{languages.map((language) => (
					<DropdownMenuItem
						key={language.code}
						onSelect={() => handleLanguageChange(language)}
						className="cursor-pointer flex items-center"
					>
						<span className="mr-2">{language.flag}</span>
						{language.name}
						{currentLanguage.code === language.code && <Check className="ml-auto h-4 w-4" />}
					</DropdownMenuItem>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

export default LanguageSwitcher;
