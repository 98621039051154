import { PaymentFrequency } from './service/PaymentFrequency';

/**
 * Determines the current period for a given date based on the payment frequency.
 *
 * @param {PaymentFrequency} frequency - The frequency of payment intervals.
 * @param {Date} date - The date for which the current period is to be determined.
 * @returns {string} - A string representing the current period in the format YYYY-MM, YYYY-QX, or YYYY.
 */
export function getCurrentPeriod(frequency: PaymentFrequency, date: Date): string {
	const year = date.getFullYear();
	const month = date.getMonth() + 1; // JavaScript months are 0-based.
	const quarter = Math.floor((month - 1) / 3) + 1;

	switch (frequency) {
		case PaymentFrequency.MONTHLY:
			return `${year}-${month.toString().padStart(2, '0')}`;
		case PaymentFrequency.QUARTERLY:
			return `${year}-Q${quarter}`;
		case PaymentFrequency.ANNUAL:
		case PaymentFrequency.BIANNUAL:
		case PaymentFrequency.SEMIANNUAL:
			return `${year}`;
		default:
			// For unsupported or complex frequencies like Biweekly, Bimonthly, use custom logic or default to Monthly
			return `${year}-${month.toString().padStart(2, '0')}`;
	}
}

/**
 * Formats a period string into a human-readable date based on locale, with the month capitalized.
 *
 * @param {string} period - The period string in the format YYYY-MM, YYYY-QX, or YYYY.
 * @param {string} locale - The locale code for formatting (e.g., "en-US" or "pt-BR").
 * @returns {string} - The formatted date string, e.g., "November, 2024" or "Novembro de 2024".
 */
export function formatPeriod(period: string, locale: string = navigator.language): string {
	const [year, monthOrQuarter] = period.split('-');

	// Handle monthly format YYYY-MM
	if (monthOrQuarter && !monthOrQuarter.startsWith('Q')) {
		const month = parseInt(monthOrQuarter, 10) - 1; // Adjust month index for Date object
		const date = new Date(parseInt(year, 10), month);
		const formattedDate = new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'long' }).format(date);
		return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1); // Capitalize the first letter
	}

	// Handle quarterly format YYYY-QX, keeping it as Q1, Q2, etc.
	if (monthOrQuarter?.startsWith('Q')) {
		return `${monthOrQuarter}, ${year}`;
	}

	// Handle annual format YYYY
	return new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(new Date(parseInt(year, 10), 0));
}

/**
 * Checks if a subscription is overdue based on the next due date and the maximum allowed overdue days.
 * @param nextDueDate The next due date of the subscription.
 * @param subscriptionMaxOverdueDays The maximum number of days a payment can be overdue.
 * @returns boolean indicating whether the subscription is overdue.
 */
export function isOverdue(nextDueDate: Date, subscriptionMaxOverdueDays: number): boolean {
	const currentDate = new Date(); // get the current date
	const overdueDate = new Date(nextDueDate); // copy nextDueDate to avoid mutating the original date
	overdueDate.setDate(overdueDate.getDate() + subscriptionMaxOverdueDays); // set the overdue limit

	return currentDate > overdueDate; // check if current date is past the overdue date
}
