import { SubscriptionHistoryModificationType } from '../../shared/models/Subscription';
import { TailwindTextColorClass } from '../../shared/tailwind';
import { CirclePlus, Clock, FastForward, LucideIcon, Pause, Play, RefreshCw, Square } from 'lucide-react';

export const CLOCK_ICON: LucideIcon = Clock;

export const PLAY_ICON: LucideIcon = Play;

export const PAUSE_ICON: LucideIcon = Pause;

export const RESUME_ICON: LucideIcon = FastForward;

export const STOP_ICON: LucideIcon = Square;

export const HISTORY_ICON: {
	[key in SubscriptionHistoryModificationType]: { type: LucideIcon; color: TailwindTextColorClass };
} = {
	Created: { type: CirclePlus, color: 'text-light' },
	Activate: { type: PLAY_ICON, color: 'text-success' },
	Suspend: { type: PAUSE_ICON, color: 'text-warning' },
	Resume: { type: RESUME_ICON, color: 'text-success' },
	Terminate: { type: STOP_ICON, color: 'text-danger' },
	Updated: { type: RefreshCw, color: 'text-info' },
};
