import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAPIVersion from '../hooks/api/useAPIVersion';
import { VERSION } from '../constants/version';
import { GitBranch } from 'lucide-react';

export interface VersionProps {
	fold: boolean;
}

function Labels() {
	const { t } = useTranslation('dashboard');
	const { loading, version } = useAPIVersion();

	return (
		<div className="text-gray-400 flex flex-col items-center">
			<span className="text-sm font-bold">{t('version')}</span>
			<span className="text-sm">{VERSION}</span>
			<span className="text-sm">API: {!loading ? version : 'fetching...'}</span>
		</div>
	);
}

export default function Versions({ fold }: VersionProps) {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<div className={`flex flex-col items-center ${fold ? 'lg:hidden' : ''}`}>
				<Labels />
			</div>
			<div className={`relative px-4 ${!fold ? 'lg:hidden' : ''}`}>
				<button
					className="rounded-lg h-12 w-12 overflow-hidden cursor-pointer flex items-center justify-center outline-none text-gray-400 hover:bg-primary-50 hover:text-primary hover:outline-none active:text-primary active:outline-none"
					onMouseEnter={() => setVisible(true)}
					onMouseLeave={() => setVisible(false)}
				>
					<GitBranch type="UilCodeBranch" />
				</button>
				<div
					className={`fixed w-56 -mt-24 ml-14 p-4 bg-white shadow-lg rounded-r-md ${!visible ? 'hidden' : ''}`}
				>
					<Labels />
				</div>
			</div>
		</>
	);
}
