import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import { APIError } from '../../shared/api_errors';
import useAPI from './useAPI';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';
import { PrismaWhereClause } from './query';

export type SortOrder = 'asc' | 'desc';

export interface PaginatedResponse<T> {
	rows: T[];
	records: number;
}

export interface UseCRUDQueryReturn<T> {
	response: PaginatedResponse<T> | null;
	loading: boolean;
	error?: APIError;
	page: number;
	pageSize: number;
	refetch: (newPage?: number, newPageSize?: number) => Promise<void>;
	setPageSize: (newPageSize: number) => void;
	setPage: (newPage: number) => void;
	sortField?: string;
	sortOrder: SortOrder;
	setOrder: (newSortField: string, newSortOrder: SortOrder) => void;
	ready: boolean;
	clear: () => void;
	clearError: () => void;
}

export class CRUDQueryOptions {
	initialSortField: string | undefined = undefined;
	initialPage: number = 1;
	initialPageSize: number = 10;
	initialSortOrder: SortOrder = 'asc';
	where?: PrismaWhereClause;
	performFetch: boolean = true;
	emptyQueryFetch: boolean = true;
	usePagination: boolean = true;
	useSorting: boolean = true;
	asSaas: boolean = false;

	constructor(options?: Partial<CRUDQueryOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

function useCRUDQuery<T>(endpoint: string, options?: Partial<CRUDQueryOptions>): UseCRUDQueryReturn<T> {
	const {
		initialPage,
		initialPageSize,
		initialSortField,
		initialSortOrder,
		where,
		performFetch,
		emptyQueryFetch,
		usePagination,
		useSorting,
		asSaas,
	} = new CRUDQueryOptions(options);

	const { tenant, saasTenant } = useProfile();
	const { user } = useAuth();
	const { get } = useAPI(true, user?.access_token, asSaas ? saasTenant || tenant : tenant);

	const [response, setResponse] = useState<PaginatedResponse<T> | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>(undefined);
	const [page, setPage] = useState<number>(initialPage);
	const [pageSize, setPageSize] = useState<number>(initialPageSize);
	const [sortField, setSortField] = useState<string | undefined>(initialSortField);
	const [sortOrder, setSortOrder] = useState<SortOrder>(initialSortOrder);

	const [debouncedWhere] = useDebounce(where, 200);

	function handleClearError() {
		setError(undefined);
	}

	function handleSetOrder(newSortField: string, newSortOrder: SortOrder) {
		setSortField(newSortField);
		setSortOrder(newSortOrder);
	}

	async function fetchData() {
		if (!tenant || !performFetch) return;
		if (!emptyQueryFetch && debouncedWhere && !Object.keys(debouncedWhere).length) return;

		setLoading(true);
		setError(undefined);
		try {
			const searchParams = new URLSearchParams({
				page: `${page}`,
				page_size: `${pageSize}`,
				sort_field: `${sortField || ''}`,
				sort_order: sortOrder,
				criteria: JSON.stringify(debouncedWhere ? debouncedWhere : {}),
			});

			if (!usePagination) {
				searchParams.delete('page');
				searchParams.delete('page_size');
			}

			if (!useSorting) {
				searchParams.delete('sort_field');
				searchParams.delete('sort_order');
			}

			const endpointQuery = [endpoint, searchParams.toString()].filter((o) => o).join('?');
			const response = await get(endpointQuery);
			const result: PaginatedResponse<T> = usePagination
				? (response as PaginatedResponse<T>)
				: ({
						rows: response,
						records: response.length,
					} as PaginatedResponse<T>);
			setResponse(result);
		} catch (error) {
			setError(
				error instanceof APIError ? error : new APIError(error instanceof Error ? error.message : `${error}`),
			);
			setResponse(null);
		} finally {
			setLoading(false);
		}
	}

	const refetch = async (newPage?: number, newPageSize?: number): Promise<void> => {
		newPage && setPage(newPage);
		newPageSize && setPageSize(newPageSize);
		await fetchData();
	};

	function clear() {
		setResponse(null);
	}

	useEffect(() => {
		fetchData();
	}, [endpoint, page, pageSize, sortField, sortOrder, tenant, performFetch, debouncedWhere]);

	return {
		response,
		loading,
		error,
		page,
		pageSize,
		refetch,
		setPageSize,
		setPage,
		sortField,
		sortOrder,
		setOrder: handleSetOrder,
		ready: !!tenant,
		clear,
		clearError: handleClearError,
	};
}

export default useCRUDQuery;
