import { useEffect, useState } from 'react';
import useAPI from './useAPI';
import { useAuth } from '../useAuth';
import { SubscriptionStatus } from '../../shared/models/saas/account/SubscriptionResponse';
import { APIError } from '../../shared/api_errors';

export interface TenantSubscription {
	status: SubscriptionStatus;
}

export interface Tenant {
	id: string;
	name: string;
	subscription?: TenantSubscription;
}

export default function useAPITenants() {
	const { user } = useAuth();

	const api = useAPI(true, user?.access_token);
	const [tenants, setTenants] = useState<Tenant[] | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<APIError>();

	async function fetchTenants() {
		setTenants(undefined);
		setLoading(true);

		try {
			const tenants = await api.get('/profile/tenants');
			setTenants(tenants);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchTenants();
	}, [user]);

	return { tenants, loading, fetchTenants, error };
}
