import React, { useState } from 'react';
import Overlay from '../components/Overlay';
import Button, { ButtonProps } from '../components/Button';
import Input from '../components/Input';
import MessageBox from './MessageBox';

interface ConfimationBoxProps {
	title: string;
	message?: string;
	inverted?: boolean;
	ok?: string;
	cancel?: string;
	confirmationText?: string;
	confirmationPlaceholder?: string;
	onOk?: () => void;
	onCancel?: () => void;
}

export default function ConfimationBox({
	title,
	message,
	inverted,
	ok = 'Confirm',
	cancel = 'Abort',
	confirmationText,
	onOk,
	onCancel,
}: ConfimationBoxProps) {
	const [inputText, setInputText] = useState('');

	const okButton: ButtonProps = inverted
		? { text: ok, variant: 'danger', style: 'outline' }
		: { text: ok, variant: 'success' };
	const cancelButton: ButtonProps = inverted
		? { text: cancel, variant: 'success' }
		: { text: cancel, variant: 'danger', style: 'outline' };

	const handleOkClick = () => {
		if (!confirmationText || inputText === confirmationText) {
			onOk?.();
		}
	};

	return (
		<Overlay>
			<MessageBox title={title}>
				<p className="text-sm text-justify">{message}</p>
				{confirmationText && (
					<div className="w-full flex flex-col items-center gap-2">
						<Input
							type="text"
							placeholder={`Type ${confirmationText} to confirm`}
							value={inputText}
							onChange={(value) => setInputText(value)}
							condensed
							rounded
							full
						/>
						<p className="text-xs flex items-center justify-center">
							Type{' '}
							<textarea
								className="font-bold resize-none h-4 text-center"
								onClick={(event) => (event.target as unknown as HTMLTextAreaElement).select()}
							>
								{confirmationText}
							</textarea>{' '}
							to confirm!
						</p>
					</div>
				)}
				<div className="flex gap-2">
					<div className="w-32">
						<Button block {...okButton} onClick={handleOkClick} />
					</div>
					<div className="w-32">
						<Button block {...cancelButton} onClick={onCancel} />
					</div>
				</div>
			</MessageBox>
		</Overlay>
	);
}
