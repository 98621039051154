import { useEffect, useState } from 'react';
import useAPI from './useAPI';

import { NASConfigurationResponse, NASConnectionResponse, NASLastSyncResponse } from '../../shared/models/infra/NAS';
import { APIError } from '../../shared/api_errors';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';

export class APINASOptions {
	mustFetchPools: boolean = false;
	mustFetchConnectionStatus: boolean = false;
	mustFetchConfiguration: boolean = false;
	mustFetchSync: boolean = false;
	asSaas: boolean = false;

	constructor(options?: Partial<APINASOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

export default function useAPINASRouterOS(nas_ids: string[] | null, options?: Partial<APINASOptions>) {
	const { mustFetchConnectionStatus, mustFetchConfiguration, mustFetchSync, asSaas } = new APINASOptions(options);

	const { tenant, saasTenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, asSaas ? saasTenant || tenant : tenant);

	const [connectionStatus, setConnectionStatus] = useState<NASConnectionResponse[] | undefined>(undefined);
	const [configuration, setConfiguration] = useState<NASConfigurationResponse[] | undefined>(undefined);
	const [sync, setSync] = useState<NASLastSyncResponse[] | undefined>(undefined);

	const [loadingConnectionStatus, setLoadingConnectionStatus] = useState<boolean>(false);
	const [loadingConfigurationCheck, setLoadingConfigurationCheck] = useState<boolean>(false);
	const [loadingSyncCheck, setLoadingSyncCheck] = useState<boolean>(false);
	const [actionLoading, setActionLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	function clearError() {
		setError(undefined);
	}

	async function fetchConnectionStatus() {
		if (!nas_ids) {
			setConnectionStatus(undefined);
			setLoadingConnectionStatus(false);
			return;
		}

		try {
			setLoadingConnectionStatus(true);
			// Convert nas_id to array if it's a single string
			const nasIds = Array.isArray(nas_ids) ? nas_ids : [nas_ids];

			// Fetch connection status for each nas_id and progressively update state
			const promises = nasIds.map(async (id) => {
				const result = await api.get(`/infra/nas/${id}/connection`);
				setConnectionStatus((prev) => (prev ? [...prev, result] : [result]));
			});

			// Wait for all the requests to finish
			await Promise.all(promises);
		} catch (error) {
			setConnectionStatus(undefined);
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingConnectionStatus(false);
		}
	}

	async function fetchConfigurations(nas_id?: string) {
		if (!nas_ids && !nas_id) {
			setConfiguration(undefined);
			setLoadingConfigurationCheck(false);
			return;
		}

		try {
			setLoadingConfigurationCheck(true);

			// Determine IDs to fetch
			const nasIds = nas_id ? [nas_id] : Array.isArray(nas_ids) ? nas_ids : [nas_ids];

			// Fetch configuration for each nas_id
			const newConfigurations = await Promise.all(
				nasIds.map(async (id) => {
					const result = await api.get(`/infra/nas/${id}/configuration`);
					return { id, result };
				}),
			);

			setConfiguration((prev) => {
				if (!prev) return newConfigurations.map(({ result }) => result);

				// Replace or append configurations
				const updatedState = [...prev];
				newConfigurations.forEach(({ id, result }) => {
					const index = updatedState.findIndex((item) => item.nas_id === id);
					if (index > -1) {
						updatedState[index] = result;
					} else {
						updatedState.push(result);
					}
				});

				return updatedState;
			});
		} catch (error) {
			setConfiguration(undefined);
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingConfigurationCheck(false);
		}
	}

	async function fetchSync(nas_id?: string) {
		if (!nas_ids && !nas_id) {
			setSync(undefined);
			setLoadingSyncCheck(false);
			return;
		}

		try {
			setLoadingSyncCheck(true);

			// Determine IDs to fetch
			const nasIds = nas_id ? [nas_id] : Array.isArray(nas_ids) ? nas_ids : [nas_ids];

			// Fetch sync data for each nas_id
			const newSyncData = await Promise.all(
				nasIds.map(async (id) => {
					const result = await api.get(`/infra/nas/${id}/sync`);
					return { id, result };
				}),
			);

			setSync((prev) => {
				if (!prev) return newSyncData.map(({ result }) => result);

				// Replace or append sync data
				const updatedState = [...prev];
				newSyncData.forEach(({ id, result }) => {
					const index = updatedState.findIndex((item) => item.nas_id === id);
					if (index > -1) {
						updatedState[index] = result;
					} else {
						updatedState.push(result);
					}
				});

				return updatedState;
			});
		} catch (error) {
			setSync(undefined);
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingSyncCheck(false);
		}
	}

	async function applyConfiguration(nas_id: string): Promise<boolean> {
		if (!nas_id) {
			setActionLoading(false);
			return false;
		}

		try {
			setError(undefined);
			setActionLoading(true);
			await api.put(`/infra/nas/${nas_id}/configuration`);
			await fetchConfigurations();
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
			return false;
		} finally {
			setActionLoading(false);
		}

		return true;
	}

	async function runSync(nas_id: string): Promise<boolean> {
		if (!nas_id) {
			setActionLoading(false);
			return false;
		}

		try {
			setError(undefined);
			setActionLoading(true);
			await api.put(`/infra/nas/${nas_id}/sync`);
			await fetchSync();
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
			return false;
		} finally {
			setActionLoading(false);
		}

		return true;
	}

	function clear() {
		setConnectionStatus(undefined);
		setConfiguration(undefined);
		setSync(undefined);
		setLoadingConnectionStatus(false);
		setLoadingConfigurationCheck(false);
		setLoadingSyncCheck(false);
		setError(undefined);
	}

	function fetch() {
		mustFetchConnectionStatus && fetchConnectionStatus();
		mustFetchConfiguration && fetchConfigurations();
		mustFetchSync && fetchSync();
	}

	useEffect(() => {
		fetch();
	}, [nas_ids, mustFetchConnectionStatus, mustFetchConfiguration, mustFetchSync]);

	return {
		connectionStatus,
		configuration,
		sync,
		loadingConnectionStatus,
		loadingConfigurationCheck,
		loadingSyncCheck,
		actionLoading,
		error,
		clear,
		clearError,
		refresh: fetch,
		applyConfiguration,
		runSync,
	};
}
