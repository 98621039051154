import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export default function Overlay({ transparent, children }: { transparent?: boolean; children: ReactNode }) {
	return ReactDOM.createPortal(
		<div className="fixed right-0 top-0 h-screen w-screen bg-black bg-opacity-50 flex justify-center items-center z-50">
			<div className={`${!transparent ? 'rounded-lg shadow-lg bg-white' : ''}`}>{children}</div>
		</div>,
		document.body,
	);
}
