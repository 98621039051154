import React from 'react';

import CRUDFormGroup, { CRUDFormGroupType } from './CRUDFormGroup';

export interface CRUDFormGroupsProps {
	form: never;
	groups: Array<CRUDFormGroupType | Array<CRUDFormGroupType>>;
	setForm: React.Dispatch<React.SetStateAction<object>>;
	loading: boolean;
}

export function CRUDFormGroups({ groups, form, setForm }: CRUDFormGroupsProps) {
	return (
		<>
			<div className="flex flex-col gap-4">
				{groups.map((groupOrGroupArray) =>
					Array.isArray(groupOrGroupArray) ? (
						<div
							key={groupOrGroupArray.map((group) => group.key).join('')}
							className="flex items-center justify-center gap-4"
						>
							{groupOrGroupArray.map((group) => (
								<div key={group.key} className="w-full">
									<CRUDFormGroup group={group} form={form} setForm={setForm} />
								</div>
							))}
						</div>
					) : (
						<CRUDFormGroup
							key={groupOrGroupArray.key}
							group={groupOrGroupArray}
							form={form as never}
							setForm={setForm}
						/>
					),
				)}
			</div>
		</>
	);
}
