import Icon from '@/components/Icon';
import { CircleAlert, CircleCheck, CircleX, LucideIcon, OctagonAlert, TriangleAlert } from 'lucide-react';
import React from 'react';

const colors = {
	info: 'bg-info text-white border-info-hdr',
	danger: 'bg-danger text-white border-danger-hdr',
	success: 'bg-success text-white border-success-hdr',
	warning: 'bg-warning text-white border-warning-hdr',
};

const icons: { [key: string]: LucideIcon } = {
	info: CircleAlert,
	danger: OctagonAlert,
	success: CircleCheck,
	warning: TriangleAlert,
};

interface AlertProps {
	type: 'info' | 'danger' | 'success' | 'warning';
	title: string;
	message?: string;
	onClose?: () => void;
}

export default function Alert({ type, title, message, onClose }: AlertProps) {
	return (
		<div className={`relative px-4 py-2 rounded-lg border-[0.5px] ${colors[type]} shadow-xl`}>
			<div className="flex items-center gap-4 justify-between">
				<div className="flex items-center gap-4">
					<span className="">
						<Icon icon={icons[type]} size={18} />
					</span>
					<div>
						<h6 className="font-bold text-sm">{title}</h6>
						<span className="font-normal text-sm">{message}</span>
					</div>
				</div>
				{onClose && (
					<button onClick={onClose} className="p-1 rounded-full hover:bg-opacity-75" aria-label="Close alert">
						<CircleX />
					</button>
				)}
			</div>
		</div>
	);
}
