import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faRefresh } from '@fortawesome/free-solid-svg-icons';

import { SortOrder } from '../hooks/api/useCRUDQuery';
import IconButton from './IconButton';
import Select from './Select';
import { ArrowDown, ArrowUp, EllipsisVertical, LoaderCircle, LucideIcon } from 'lucide-react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './ui/dropdown-menu';
import Icon from './Icon';

export interface DataTableField<T> {
	title: string;
	property: string;
	align?: 'left' | 'center' | 'right';
	extractor?: (row: T) => string | ReactNode;
}

export interface DataTablePageConfig {
	size: number;
	page: number;
	total: number;
}

export interface DataTableSortConfig {
	field: string;
	order: SortOrder;
}

export interface DataTableAction<T> {
	icon?: LucideIcon;
	title: string;
	action: (row: T) => void;
}

export interface DataTableProps<T> {
	idField?: string;
	fields: Array<DataTableField<T>>;
	actions?: Array<DataTableAction<T>>;
	rows?: Array<T>;
	pages?: DataTablePageConfig;
	onPageSize?: (pageSize: number) => void;
	onPage?: (page: number) => void;
	onRefresh?: () => void;
	loading?: boolean;
	sort?: DataTableSortConfig;
	onSort?: (newSortField: string, newSortOrder: SortOrder) => void;
	style?: 'standard' | 'condensed';
	onRowAction?: (row: T) => boolean;
}

export type DataTableRow = { [key: string]: unknown };

export default function DataTable<T>({
	idField = 'id',
	fields,
	actions,
	rows,
	pages,
	onPageSize,
	onPage,
	onRefresh,
	loading,
	sort,
	onSort,
	style = 'standard',
	onRowAction = () => true,
}: DataTableProps<T>) {
	const totalpages = pages ? Math.ceil(pages.total / pages.size) : 0;
	// const [openedAction, setOpenedAction] = useState<string | undefined>();

	function handlePageSize(value: string) {
		onPageSize && onPageSize(parseInt(value));
	}

	function handlePage(page: number) {
		if (page < 1 || page > totalpages) return;
		onPage && onPage(page);
	}

	function handleOnRefresh() {
		onRefresh && onRefresh();
	}

	function handleSort(sortField: string) {
		const newField = sortField;
		const newOrder = sort && sort.order === 'asc' && sort.field === sortField ? 'desc' : 'asc';
		onSort && onSort(newField, newOrder);
	}

	const wrapperClasses = style === 'condensed' ? 'rounded-lg' : '';
	const tableClasses =
		style === 'condensed'
			? 'text-left text-sm w-full leading-[1.6] border-[1px] border-gray-100'
			: 'text-left text-sm w-full leading-[1.6]';
	const theadClasses =
		style === 'condensed'
			? 'bg-[#f8f9fb] text-light'
			: 'border-b font-normal px-[1.4rem] border-neutral-200 text-gray-900';
	const thClasses =
		style === 'condensed'
			? 'px-4 py-2.5 text-clip overflow-hidden cursor-pointer'
			: 'py-4 pl-1 text-clip overflow-hidden cursor-pointer';
	const trClasses = style === 'condensed' ? '' : 'border-b';
	const tdClasses =
		style === 'condensed'
			? 'px-4 py-2.5 border-none before:hidden whitespace-nowrap'
			: 'whitespace-nowrap text-clip overflow-hidden px-[1.4rem] py-4 border-neutral-200';

	return (
		<div className="bg-white">
			<div className={`relative ${wrapperClasses}`}>
				{loading && (
					<div className="absolute inset-0 bg-light-extra bg-opacity-10 z-10 flex justify-center items-center">
						<LoaderCircle className="animate-spin" />
					</div>
				)}
				<div className="relative w-full overflow-auto custom-scrollbar">
					<div className="flex min-w-full">
						<div className="min-w-full flex-shrink-0">
							<table className={tableClasses}>
								<thead className={theadClasses}>
									<tr>
										{fields.map((field) => (
											<th
												key={field.property}
												className={thClasses}
												scope="col"
												onClick={() => handleSort(field.property)}
											>
												<div className="flex flex-row group">
													{(sort && sort.field === field.property && (
														<span
															className={`flex items-center origin-bottom font-black mr-1 ${sort && sort.field === field.property ? 'opacity-100' : 'opacity-0'} text-neutral-500 group-hover:opacity-100 transition hover:ease-in-out transform ease-linear duration-300 motion-reduce:transition-none`}
														>
															{sort.order === 'asc' ? (
																<ArrowDown size={18} />
															) : (
																<ArrowUp size={18} />
															)}
														</span>
													)) || <span className={style === 'condensed' ? '' : 'w-4'} />}
													<span className={`w-full text-${field.align || 'left'}`}>
														{field.title}
													</span>
												</div>
											</th>
										))}
										{actions && <th></th>}
									</tr>
								</thead>
								<tbody>
									{rows?.map((row) => (
										<tr
											key={`${(row as DataTableRow)[idField]}`}
											className={`border-neutral-200 transition ease-in-out duration-300 motion-reduce:transition-none ${trClasses}`}
										>
											{fields.map((field) => (
												<td
													key={`${(row as DataTableRow)[idField]}-${field.property}`}
													className={`${tdClasses} text-${field.align || 'left'}`}
												>
													{field.extractor
														? field.extractor(row)
														: `${(row as DataTableRow)[field.property] || ''}`}
												</td>
											))}
											{actions && onRowAction(row) && (
												<td className="relative border-neutral-200 w-10">
													<DropdownMenu key={`${(row as DataTableRow)[idField]}`}>
														<DropdownMenuTrigger asChild>
															<span>
																<IconButton iconType={EllipsisVertical} />
															</span>
														</DropdownMenuTrigger>
														<DropdownMenuContent className={'w-[140px] bg-white'}>
															{actions.map((action) => (
																<DropdownMenuItem
																	key={action.title
																		.replaceAll(' ', '')
																		.toLocaleLowerCase()}
																	onSelect={() => {
																		action.action(row);
																	}}
																	className="cursor-pointer flex items-center hover:bg-primary-50"
																>
																	{action.icon && <Icon icon={action.icon} />}
																	{action.title}
																</DropdownMenuItem>
															))}
														</DropdownMenuContent>
													</DropdownMenu>
												</td>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			{pages && (
				<div className="flex md:flex-row justify-end items-center py-2 space-x-4 text-sm flex-col leading-[1.6] border-neutral-200">
					<div className="flex items-center space-x-4 order-3 md:order-none">
						<button
							className={`${loading ? 'animate-spin' : ''} ${onRefresh || loading ? 'inline-block' : 'hidden'} rounded p-2.5 leading-normal transition duration-150 ease-in-out hover:bg-neutral-100 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 disabled:text-slate-300 disabled:hover:bg-transparent`}
							onClick={handleOnRefresh}
						>
							<FontAwesomeIcon icon={faRefresh} size="sm" />
						</button>
						<p className="font-light ">Rows/page:</p>
						<div className="w-20">
							<Select
								condensed={true}
								value={pages.size.toString()}
								options={[5, 10, 25, 50].map((n) => ({
									key: n.toString(),
									label: n.toString(),
								}))}
								onChange={(value) => handlePageSize(value)}
							/>
						</div>
					</div>
					<div className="font-normal order-2 mb-3 md:order-none md:mb-0 ">
						{pages.page > 1 ? pages.size * (pages.page - 1) : 1} -&nbsp;
						{pages.size * pages.page > pages.total ? pages.total : pages.size * pages.page} de&nbsp;
						{pages.total}
					</div>
					<div className="order-1 my-3 md:order-none md:my-0 md:pr-1">
						<button
							className="inline-block rounded p-2.5 leading-normal transition duration-150 ease-in-out hover:bg-neutral-100 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 disabled:text-slate-300 disabled:hover:bg-transparent"
							onClick={() => handlePage(pages.page - 1)}
						>
							<FontAwesomeIcon icon={faChevronLeft} size="sm" />
						</button>
						<button
							className="inline-block rounded p-2.5 leading-normal transition duration-150 ease-in-out hover:bg-neutral-100 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 disabled:text-slate-300 disabled:hover:bg-transparent"
							onClick={() => handlePage(pages.page + 1)}
						>
							<FontAwesomeIcon icon={faChevronRight} size="sm" />
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
