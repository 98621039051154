import { useEffect, useState } from 'react';
import useAPI from './useAPI';
import { useAuth } from '../useAuth';
import { SessionUser } from '../../shared/models/User';
import { APIError } from '../../shared/api_errors';

export default function useAPIProfile() {
	const { user } = useAuth();

	const api = useAPI(true, user?.access_token);
	const [sessionUser, setSessionUser] = useState<SessionUser | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<APIError>();

	async function fetchProfile() {
		setSessionUser(undefined);
		setLoading(true);

		try {
			const profile = await api.get('/profile');
			setSessionUser(profile);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchProfile();
	}, [user]);

	return { sessionUser, loading, fetchProfile, error };
}
