import React, { useEffect, useState } from 'react';

import DataTable, { DataTableField } from '../components/DataTable';
import Filters, { Filter } from '../slices/Filters';

import { SubscriptionPayment } from '../shared/models/Subscription';
import { applyMask } from '../components/Input';
import { getUTCDate, toLocaleLongDateString } from '../shared/utils';
import useCRUDPage from '../hooks/useCRUDPage';
import { PrismaWhereClause } from '../hooks/api/query';
import WrapperPage from './WrapperPage';
import WhiteBox from '../components/WhiteBox';
import Selection from '../slices/Selection';
import { formatPeriod } from '../shared/models/SubscriptionDueDateUtils';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { SquarePen } from 'lucide-react';

const getFields = (t: TFunction, navigate: NavigateFunction): Array<DataTableField<SubscriptionPayment>> => [
	{
		title: t('sph.fields.customer'),
		property: 'service_subscription.client.name',
		extractor: (row: SubscriptionPayment) =>
			`${row.service_subscription.client.name} (${row.service_subscription.connectivity_user})`,
	},
	{
		title: t('sph.fields.plan'),
		property: 'service_subscription.service_plan_name',
		extractor: (row: SubscriptionPayment) => `${row.service_subscription.service_plan_name}`,
	},
	{
		title: t('sph.fields.paid-at'),
		property: 'payment_date',
		extractor: (row) => toLocaleLongDateString(row.payment_date),
	},
	{ title: t('sph.fields.period'), property: 'period', extractor: (row) => formatPeriod(row.period) },
	{
		title: t('sph.fields.amount'),
		property: 'amount',
		extractor: (row: SubscriptionPayment) => applyMask(row.amount.toString()),
	},
	{
		title: '',
		property: '',
		extractor: (row: SubscriptionPayment) => (
			<Button
				icon={SquarePen}
				style="roundedOutline"
				size="xs"
				onClick={() => navigate(`/subscriptions/${row.service_subscription_id}`)}
			/>
		),
	},
];

const subscriptionHistoryTabs = ['full-history', 'recent-payments'] as const;
type SubscriptionHistoryTabName = (typeof subscriptionHistoryTabs)[number];

function getWhereRecentPayments(referenceDate: Date = new Date(new Date().setUTCHours(0, 0, 0, 0))): PrismaWhereClause {
	return {
		AND: [
			{
				payment_date: {
					gte: getUTCDate(-7, referenceDate),
				},
			},
			{
				payment_date: {
					lt: getUTCDate(1, referenceDate),
				},
			},
		],
	};
}

export default function SubscriptionPaymentHistoryPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState<SubscriptionHistoryTabName>('recent-payments');
	const [where, setWhere] = useState<PrismaWhereClause | undefined>(getWhereRecentPayments());

	const filters: Array<Filter> = [
		{
			id: 'customer',
			label: `${t('sph.filters.customer')}:`,
			operator: {
				field: 'service_subscription.client.name',
				op: 'contains',
			},
		},
	];

	const {
		data,
		error: queryError,
		loading: queryLoading,
		page,
		pageSize,
		totalRecords,
		sortField,
		sortOrder,
		filterValues,
		handleOnPageSize,
		handleOnPage,
		handleOnRefresh,
		handleOnSort,
		handleFilterChange,
		handleClearError: queryClearError,
	} = useCRUDPage<SubscriptionPayment>({
		endpoint: '/service/subscriptions/payments',
		initialSortField: 'payment_date',
		filters,
		fixedWhere: where,
		usePagination: true,
		useSorting: true,
	});

	function handleSelectionOnSelect(selectedTab: string) {
		setActiveTab(selectedTab as SubscriptionHistoryTabName);
	}

	useEffect(() => {
		switch (activeTab) {
			case 'full-history':
				setWhere({});
				break;
			case 'recent-payments':
				setWhere(getWhereRecentPayments());
				break;
		}
	}, [activeTab]);

	function handleClearError() {
		queryClearError();
	}

	const fields = getFields(t, navigate);

	const error = queryError;

	return (
		<WrapperPage title="Subscriptions Payment History" error={error} onAlertClose={handleClearError}>
			<div className="mx-4 mb-4 flex flex-col gap-4">
				<div className="flex flex-col gap-4">
					{filters && (
						<Filters filters={filters} filterValues={filterValues} onFilterChange={handleFilterChange} />
					)}
				</div>
				<WhiteBox className="flex flex-col gap-4">
					<div className="flex">
						<Selection
							tabs={[
								{ key: 'full-history', label: t('sph.tabs.full-history') },
								{ key: 'recent-payments', label: t('sph.tabs.recent-payments') },
							]}
							onSelect={handleSelectionOnSelect}
							active={activeTab}
						/>
					</div>
					<DataTable
						fields={fields}
						rows={data}
						pages={{ page, size: pageSize, total: totalRecords }}
						onPageSize={handleOnPageSize}
						onPage={handleOnPage}
						onRefresh={handleOnRefresh}
						loading={queryLoading}
						sort={sortField ? { field: sortField, order: sortOrder } : undefined}
						onSort={handleOnSort}
					/>
				</WhiteBox>
			</div>
		</WrapperPage>
	);
}
