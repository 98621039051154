import { TenantRole } from '../../context/ProfileContext';
import config from '../../shared/config';
import { useCallback } from 'react';
import { APIError } from '../../shared/api_errors';

const useAPI = (auth: boolean = false, token?: string, tenant?: TenantRole) => {
	const fetchApi = useCallback(
		async (
			endpoint: RequestInfo | URL,
			options: { method?: string; headers?: Record<string, string>; body?: unknown } = {},
		) => {
			const isFormData = options.body instanceof FormData;
			const body = options.body
				? options.body instanceof FormData
					? options.body
					: JSON.stringify(options.body)
				: null;

			const headers: Record<string, string> = {
				...options.headers,
			};

			if (!isFormData) {
				headers['Accept'] = 'application/json';
				headers['Content-Type'] = 'application/json';
			}

			if (auth) {
				if (token) headers.authorization = `Bearer ${token}`;
				else return;
			}

			if (tenant) {
				headers['X-Tenant-ID'] = tenant.id;
			}

			const url = `${config.api.url}${endpoint}`;

			const response = await fetch(url, {
				method: options.method || 'GET',
				headers,
				body,
			});

			if (!response.ok) {
				const errorResponse = await response.json();
				const messages = errorResponse.message;
				// Assume it's a Pydantic validation error
				if (Array.isArray(messages)) {
					throw new APIError('Validation Error', response.status, messages);
				} else {
					throw new APIError(messages, response.status);
				}
			}

			try {
				// Try to parse the response as JSON
				const contentType = response.headers.get('Content-Type') || '';
				const isText = contentType.indexOf('text/') > -1;
				if (isText) {
					return response.text();
				}

				const json = await response.json();
				return json;
			} catch (error) {
				// If parsing fails, return undefined
				return;
			}
		},
		[token, tenant],
	);

	const get = useCallback(
		(endpoint: string, options = {}) => {
			return fetchApi(endpoint, { ...options, method: 'GET' });
		},
		[token, tenant],
	);

	const post = useCallback(
		(endpoint: string, options = {}) => {
			return fetchApi(endpoint, { ...options, method: 'POST' });
		},
		[token, tenant],
	);

	const put = useCallback(
		(endpoint: string, options = {}) => {
			return fetchApi(endpoint, { ...options, method: 'PUT' });
		},
		[token, tenant],
	);

	const del = useCallback(
		(endpoint: string, options = {}) => {
			return fetchApi(endpoint, { ...options, method: 'DELETE' });
		},
		[token, tenant],
	);

	return { get, post, put, del };
};

export default useAPI;
